<template>
    <div class="col-lg-6">
        <div class="kt-portlet kt-portlet--height-fluid">
            <div class="kt-portlet__body">
                <div class="kt-infobox">
                    <div class="kt-infobox__header">
                        <h2 class="kt-infobox__title">
                            {{ $t("faq_trouble") }}
                        </h2>
                    </div>
                    <div class="kt-infobox__body">
                        <div
                            class="accordion accordion-light  accordion-svg-icon"
                            id="accordionFaqTrouble"
                        >
                            <div class="card">
                                <div class="card-header" id="headingOne112">
                                    <div
                                        class="card-title collapsed"
                                        data-toggle="collapse"
                                        data-target="#collapseOne112"
                                        aria-expanded="false"
                                        aria-controls="collapseOne112"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            width="24px"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            version="1.1"
                                            class="kt-svg-icon"
                                        >
                                            <g
                                                stroke="none"
                                                stroke-width="1"
                                                fill="none"
                                                fill-rule="evenodd"
                                            >
                                                <polygon
                                                    points="0 0 24 0 24 24 0 24"
                                                ></polygon>
                                                <path
                                                    d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                ></path>
                                                <path
                                                    d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                    opacity="0.3"
                                                    transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                ></path>
                                            </g>
                                        </svg>
                                        {{ $t("faq_trouble_1_title") }}
                                    </div>
                                </div>
                                <div
                                    id="collapseOne112"
                                    class="collapse"
                                    aria-labelledby="headingOne84"
                                    data-parent="#accordionFaqTrouble"
                                    style=""
                                >
                                    <div class="card-body">
                                        {{ $t("faq_trouble_1_text1") }}
                                        <div class="divImgFaq">
                                            <img
                                                v-if="this.$i18n.locale === 'fr'"
                                                class="imgFaq"
                                                src="/assets/faq/fr/63.png"
                                            />
                                            <img
                                                v-else
                                                class="imgFaq"
                                                src="/assets/faq/en/63.png"
                                            />
                                        </div>
                                        {{ $t("faq_trouble_1_text2") }}<br>
                                        {{ $t("faq_trouble_1_text3") }}<br>
                                        {{ $t("faq_trouble_1_text4") }}<br>
                                        <div class="divImgFaq">
                                            <img
                                                v-if="this.$i18n.locale === 'fr'"
                                                class="imgFaq"
                                                src="/assets/faq/fr/64.png"
                                            />
                                            <img
                                                v-else
                                                class="imgFaq"
                                                src="/assets/faq/en/64.png"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="headingOne113">
                                    <div
                                        class="card-title collapsed"
                                        data-toggle="collapse"
                                        data-target="#collapseOne113"
                                        aria-expanded="false"
                                        aria-controls="collapseOne113"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            width="24px"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            version="1.1"
                                            class="kt-svg-icon"
                                        >
                                            <g
                                                stroke="none"
                                                stroke-width="1"
                                                fill="none"
                                                fill-rule="evenodd"
                                            >
                                                <polygon
                                                    points="0 0 24 0 24 24 0 24"
                                                ></polygon>
                                                <path
                                                    d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                ></path>
                                                <path
                                                    d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                    opacity="0.3"
                                                    transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                ></path>
                                            </g>
                                        </svg>
                                        {{ $t("faq_trouble_2_title") }}
                                    </div>
                                </div>
                                <div
                                    id="collapseOne113"
                                    class="collapse"
                                    aria-labelledby="headingOne84"
                                    data-parent="#accordionFaqTrouble"
                                    style=""
                                >
                                    <div class="card-body">
                                        <div class="divImgFaq">
                                            <img
                                                v-if="this.$i18n.locale === 'fr'"
                                                class="imgFaq"
                                                src="/assets/faq/fr/65.png"
                                            />
                                            <img
                                                v-else
                                                class="imgFaq"
                                                src="/assets/faq/en/65.png"
                                            />
                                        </div>
                                        {{ $t("faq_trouble_2_text1") }}
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="headingOne114">
                                    <div
                                        class="card-title collapsed"
                                        data-toggle="collapse"
                                        data-target="#collapseOne114"
                                        aria-expanded="false"
                                        aria-controls="collapseOne114"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            width="24px"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            version="1.1"
                                            class="kt-svg-icon"
                                        >
                                            <g
                                                stroke="none"
                                                stroke-width="1"
                                                fill="none"
                                                fill-rule="evenodd"
                                            >
                                                <polygon
                                                    points="0 0 24 0 24 24 0 24"
                                                ></polygon>
                                                <path
                                                    d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                ></path>
                                                <path
                                                    d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                    opacity="0.3"
                                                    transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                ></path>
                                            </g>
                                        </svg>
                                        {{ $t("faq_trouble_3_title") }}
                                    </div>
                                </div>
                                <div
                                    id="collapseOne114"
                                    class="collapse"
                                    aria-labelledby="headingOne114"
                                    data-parent="#accordionFaqTrouble"
                                    style=""
                                >
                                    <div class="card-body">
                                        {{ $t("faq_trouble_3_text1") }}
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="headingFaqtrouble13">
                                    <div
                                        class="card-title collapsed"
                                        data-toggle="collapse"
                                        data-target="#collapseFaqtrouble13"
                                        aria-expanded="false"
                                        aria-controls="collapseFaqtrouble13"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            width="24px"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            version="1.1"
                                            class="kt-svg-icon"
                                        >
                                            <g
                                                stroke="none"
                                                stroke-width="1"
                                                fill="none"
                                                fill-rule="evenodd"
                                            >
                                                <polygon
                                                    points="0 0 24 0 24 24 0 24"
                                                ></polygon>
                                                <path
                                                    d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                ></path>
                                                <path
                                                    d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                    opacity="0.3"
                                                    transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                ></path>
                                            </g>
                                        </svg>
                                        {{ $t("faq_trouble_13_title") }}
                                    </div>
                                </div>
                                <div
                                    id="collapseFaqtrouble13"
                                    class="collapse"
                                    aria-labelledby="headingFaqtrouble13"
                                    data-parent="#accordionFaqTrouble"
                                    style=""
                                >
                                    <div class="card-body">
                                        {{ $t("faq_trouble_13_text1") }}
                                        <br>
                                        {{ $t("faq_trouble_13_text2") }}
                                        <br>
                                        {{ $t("faq_trouble_13_text3") }}
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="headingOne115">
                                    <div
                                        class="card-title collapsed"
                                        data-toggle="collapse"
                                        data-target="#collapseOne115"
                                        aria-expanded="false"
                                        aria-controls="collapseOne115"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            width="24px"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            version="1.1"
                                            class="kt-svg-icon"
                                        >
                                            <g
                                                stroke="none"
                                                stroke-width="1"
                                                fill="none"
                                                fill-rule="evenodd"
                                            >
                                                <polygon
                                                    points="0 0 24 0 24 24 0 24"
                                                ></polygon>
                                                <path
                                                    d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                ></path>
                                                <path
                                                    d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                    opacity="0.3"
                                                    transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                ></path>
                                            </g>
                                        </svg>
                                        {{ $t("faq_trouble_4_title") }}
                                    </div>
                                </div>
                                <div
                                    id="collapseOne115"
                                    class="collapse"
                                    aria-labelledby="headingOne115"
                                    data-parent="#accordionFaqTrouble"
                                    style=""
                                >
                                    <div class="card-body">
                                        {{ $t("faq_trouble_4_text1") }}
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="headingOne116">
                                    <div
                                        class="card-title collapsed"
                                        data-toggle="collapse"
                                        data-target="#collapseOne116"
                                        aria-expanded="false"
                                        aria-controls="collapseOne116"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            width="24px"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            version="1.1"
                                            class="kt-svg-icon"
                                        >
                                            <g
                                                stroke="none"
                                                stroke-width="1"
                                                fill="none"
                                                fill-rule="evenodd"
                                            >
                                                <polygon
                                                    points="0 0 24 0 24 24 0 24"
                                                ></polygon>
                                                <path
                                                    d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                ></path>
                                                <path
                                                    d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                    opacity="0.3"
                                                    transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                ></path>
                                            </g>
                                        </svg>
                                        {{ $t("faq_trouble_5_title") }}
                                    </div>
                                </div>
                                <div
                                    id="collapseOne116"
                                    class="collapse"
                                    aria-labelledby="headingOne116"
                                    data-parent="#accordionFaqTrouble"
                                    style=""
                                >
                                    <div class="card-body">
                                        <div class="divImgFaq">
                                            <img
                                                v-if="this.$i18n.locale === 'fr'"
                                                class="imgFaq"
                                                src="/assets/faq/fr/66.png"
                                            />
                                            <img
                                                v-else
                                                class="imgFaq"
                                                src="/assets/faq/en/66.png"
                                            />
                                        </div>
                                        {{ $t("faq_trouble_5_text1") }}
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="headingOne117">
                                    <div
                                        class="card-title collapsed"
                                        data-toggle="collapse"
                                        data-target="#collapseOne117"
                                        aria-expanded="false"
                                        aria-controls="collapseOne117"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            width="24px"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            version="1.1"
                                            class="kt-svg-icon"
                                        >
                                            <g
                                                stroke="none"
                                                stroke-width="1"
                                                fill="none"
                                                fill-rule="evenodd"
                                            >
                                                <polygon
                                                    points="0 0 24 0 24 24 0 24"
                                                ></polygon>
                                                <path
                                                    d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                ></path>
                                                <path
                                                    d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                    opacity="0.3"
                                                    transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                ></path>
                                            </g>
                                        </svg>
                                        {{ $t("faq_trouble_6_title") }}
                                    </div>
                                </div>
                                <div
                                    id="collapseOne117"
                                    class="collapse"
                                    aria-labelledby="headingOne117"
                                    data-parent="#accordionFaqTrouble"
                                    style=""
                                >
                                    <div class="card-body">
                                        {{ $t("faq_trouble_6_text1") }}
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="headingOne118">
                                    <div
                                        class="card-title collapsed"
                                        data-toggle="collapse"
                                        data-target="#collapseOne118"
                                        aria-expanded="false"
                                        aria-controls="collapseOne118"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            width="24px"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            version="1.1"
                                            class="kt-svg-icon"
                                        >
                                            <g
                                                stroke="none"
                                                stroke-width="1"
                                                fill="none"
                                                fill-rule="evenodd"
                                            >
                                                <polygon
                                                    points="0 0 24 0 24 24 0 24"
                                                ></polygon>
                                                <path
                                                    d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                ></path>
                                                <path
                                                    d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                    opacity="0.3"
                                                    transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                ></path>
                                            </g>
                                        </svg>
                                        {{ $t("faq_trouble_7_title") }}
                                    </div>
                                </div>
                                <div
                                    id="collapseOne118"
                                    class="collapse"
                                    aria-labelledby="headingOne118"
                                    data-parent="#accordionFaqTrouble"
                                    style=""
                                >
                                    <div class="card-body">
                                        {{ $t("faq_trouble_7_text1") }}
                                        <div class="divImgFaq">
                                            <img
                                                v-if="this.$i18n.locale === 'fr'"
                                                class="imgFaq"
                                                src="/assets/faq/fr/67.png"
                                            />
                                            <img
                                                v-else
                                                class="imgFaq"
                                                src="/assets/faq/en/67.png"
                                            />
                                        </div>
                                        <div class="divImgFaq">
                                            <img
                                                v-if="this.$i18n.locale === 'fr'"
                                                class="imgFaq"
                                                src="/assets/faq/fr/68.png"
                                            />
                                            <img
                                                v-else
                                                class="imgFaq"
                                                src="/assets/faq/en/68.png"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="headingFaqTrouble14">
                                    <div
                                        class="card-title collapsed"
                                        data-toggle="collapse"
                                        data-target="#collapseFaqTrouble14"
                                        aria-expanded="false"
                                        aria-controls="collapseFaqTrouble14"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            width="24px"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            version="1.1"
                                            class="kt-svg-icon"
                                        >
                                            <g
                                                stroke="none"
                                                stroke-width="1"
                                                fill="none"
                                                fill-rule="evenodd"
                                            >
                                                <polygon
                                                    points="0 0 24 0 24 24 0 24"
                                                ></polygon>
                                                <path
                                                    d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                ></path>
                                                <path
                                                    d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                    opacity="0.3"
                                                    transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                ></path>
                                            </g>
                                        </svg>
                                        {{ $t("faq_trouble_14_title") }}
                                    </div>
                                </div>
                                <div
                                    id="collapseFaqTrouble14"
                                    class="collapse"
                                    aria-labelledby="headingFaqTrouble14"
                                    data-parent="#accordionFaqTrouble"
                                    style=""
                                >
                                    <div class="card-body">
                                        {{ $t("faq_trouble_14_text1") }}
                                        <br>
                                        {{ $t("faq_trouble_14_text2") }}
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="headingOne119">
                                    <div
                                        class="card-title collapsed"
                                        data-toggle="collapse"
                                        data-target="#collapseOne119"
                                        aria-expanded="false"
                                        aria-controls="collapseOne119"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            width="24px"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            version="1.1"
                                            class="kt-svg-icon"
                                        >
                                            <g
                                                stroke="none"
                                                stroke-width="1"
                                                fill="none"
                                                fill-rule="evenodd"
                                            >
                                                <polygon
                                                    points="0 0 24 0 24 24 0 24"
                                                ></polygon>
                                                <path
                                                    d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                ></path>
                                                <path
                                                    d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                    opacity="0.3"
                                                    transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                ></path>
                                            </g>
                                        </svg>
                                        {{ $t("faq_trouble_8_title") }}
                                    </div>
                                </div>
                                <div
                                    id="collapseOne119"
                                    class="collapse"
                                    aria-labelledby="headingOne119"
                                    data-parent="#accordionFaqTrouble"
                                    style=""
                                >
                                    <div class="card-body">
                                        <div class="divImgFaq">
                                            <img
                                                v-if="this.$i18n.locale === 'fr'"
                                                class="imgFaq"
                                                src="/assets/faq/fr/69.png"
                                            />
                                            <img
                                                v-else
                                                class="imgFaq"
                                                src="/assets/faq/en/69.png"
                                            />
                                        </div>
                                        {{ $t("faq_trouble_8_text1") }}
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="card">
                                <div class="card-header" id="headingOne120">
                                    <div
                                        class="card-title collapsed"
                                        data-toggle="collapse"
                                        data-target="#collapseOne120"
                                        aria-expanded="false"
                                        aria-controls="collapseOne120"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            width="24px"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            version="1.1"
                                            class="kt-svg-icon"
                                        >
                                            <g
                                                stroke="none"
                                                stroke-width="1"
                                                fill="none"
                                                fill-rule="evenodd"
                                            >
                                                <polygon
                                                    points="0 0 24 0 24 24 0 24"
                                                ></polygon>
                                                <path
                                                    d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                ></path>
                                                <path
                                                    d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                    opacity="0.3"
                                                    transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                ></path>
                                            </g>
                                        </svg>
                                        {{ $t("faq_trouble_9_title") }}
                                    </div>
                                </div>
                                <div
                                    id="collapseOne120"
                                    class="collapse"
                                    aria-labelledby="headingOne120"
                                    data-parent="#accordionFaqTrouble"
                                    style=""
                                >
                                    <div class="card-body">
                                        {{ $t("faq_trouble_9_text1") }}
                                    </div>
                                </div>
                            </div> -->

                            <div class="card">
                                <div class="card-header" id="headingOne121">
                                    <div
                                        class="card-title collapsed"
                                        data-toggle="collapse"
                                        data-target="#collapseOne121"
                                        aria-expanded="false"
                                        aria-controls="collapseOne121"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            width="24px"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            version="1.1"
                                            class="kt-svg-icon"
                                        >
                                            <g
                                                stroke="none"
                                                stroke-width="1"
                                                fill="none"
                                                fill-rule="evenodd"
                                            >
                                                <polygon
                                                    points="0 0 24 0 24 24 0 24"
                                                ></polygon>
                                                <path
                                                    d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                ></path>
                                                <path
                                                    d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                    opacity="0.3"
                                                    transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                ></path>
                                            </g>
                                        </svg>
                                        {{ $t("faq_trouble_10_title") }}
                                    </div>
                                </div>
                                <div
                                    id="collapseOne121"
                                    class="collapse"
                                    aria-labelledby="headingOne121"
                                    data-parent="#accordionFaqTrouble"
                                    style=""
                                >
                                    <div class="card-body">
                                        {{ $t("faq_trouble_10_text1") }}
                                        <div class="divImgFaq">
                                            <img
                                                v-if="this.$i18n.locale === 'fr'"
                                                class="imgFaq"
                                                src="/assets/faq/fr/70.png"
                                            />
                                            <img
                                                v-else
                                                class="imgFaq"
                                                src="/assets/faq/en/70.png"
                                            />
                                        </div>
                                        <div class="divImgFaq">
                                            <img
                                                v-if="this.$i18n.locale === 'fr'"
                                                class="imgFaq"
                                                src="/assets/faq/fr/71.png"
                                            />
                                            <img
                                                v-else
                                                class="imgFaq"
                                                src="/assets/faq/en/71.png"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="headingOne122">
                                    <div
                                        class="card-title collapsed"
                                        data-toggle="collapse"
                                        data-target="#collapseOne122"
                                        aria-expanded="false"
                                        aria-controls="collapseOne122"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            width="24px"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            version="1.1"
                                            class="kt-svg-icon"
                                        >
                                            <g
                                                stroke="none"
                                                stroke-width="1"
                                                fill="none"
                                                fill-rule="evenodd"
                                            >
                                                <polygon
                                                    points="0 0 24 0 24 24 0 24"
                                                ></polygon>
                                                <path
                                                    d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                ></path>
                                                <path
                                                    d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                    opacity="0.3"
                                                    transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                ></path>
                                            </g>
                                        </svg>
                                        {{ $t("faq_trouble_11_title") }}
                                    </div>
                                </div>
                                <div
                                    id="collapseOne122"
                                    class="collapse"
                                    aria-labelledby="headingOne122"
                                    data-parent="#accordionFaqTrouble"
                                    style=""
                                >
                                    <div class="card-body">
                                        <div class="divImgFaq">
                                            <img
                                                v-if="this.$i18n.locale === 'fr'"
                                                class="imgFaq"
                                                src="/assets/faq/fr/72.png"
                                            />
                                            <img
                                                v-else
                                                class="imgFaq"
                                                src="/assets/faq/en/72.png"
                                            />
                                        </div>
                                        {{ $t("faq_trouble_11_text1") }}
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="headingOne123">
                                    <div
                                        class="card-title collapsed"
                                        data-toggle="collapse"
                                        data-target="#collapseOne123"
                                        aria-expanded="false"
                                        aria-controls="collapseOne123"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            width="24px"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            version="1.1"
                                            class="kt-svg-icon"
                                        >
                                            <g
                                                stroke="none"
                                                stroke-width="1"
                                                fill="none"
                                                fill-rule="evenodd"
                                            >
                                                <polygon
                                                    points="0 0 24 0 24 24 0 24"
                                                ></polygon>
                                                <path
                                                    d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                ></path>
                                                <path
                                                    d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                    opacity="0.3"
                                                    transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                ></path>
                                            </g>
                                        </svg>
                                        {{ $t("faq_trouble_12_title") }}
                                    </div>
                                </div>
                                <div
                                    id="collapseOne123"
                                    class="collapse"
                                    aria-labelledby="headingOne123"
                                    data-parent="#accordionFaqTrouble"
                                    style=""
                                >
                                    <div class="card-body">
                                        {{ $t("faq_trouble_12_text1") }}
                                        <div class="divImgFaq">
                                            <img
                                                v-if="this.$i18n.locale === 'fr'"
                                                class="imgFaq"
                                                src="/assets/faq/fr/73.png"
                                            />
                                            <img
                                                v-else
                                                class="imgFaq"
                                                src="/assets/faq/en/73.png"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="headingFaqTrouble15">
                                    <div
                                        class="card-title collapsed"
                                        data-toggle="collapse"
                                        data-target="#collapseFaqTrouble15"
                                        aria-expanded="false"
                                        aria-controls="collapseFaqTrouble15"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            width="24px"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            version="1.1"
                                            class="kt-svg-icon"
                                        >
                                            <g
                                                stroke="none"
                                                stroke-width="1"
                                                fill="none"
                                                fill-rule="evenodd"
                                            >
                                                <polygon
                                                    points="0 0 24 0 24 24 0 24"
                                                ></polygon>
                                                <path
                                                    d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                ></path>
                                                <path
                                                    d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                    opacity="0.3"
                                                    transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                ></path>
                                            </g>
                                        </svg>
                                        {{ $t("faq_trouble_15_title") }}
                                    </div>
                                </div>
                                <div
                                    id="collapseFaqTrouble15"
                                    class="collapse"
                                    aria-labelledby="headingFaqTrouble15"
                                    data-parent="#accordionFaqTrouble"
                                    style=""
                                >
                                    <div class="card-body">
                                        {{ $t("faq_trouble_15_text1") }}
                                        <div class="divImgFaq">
                                            <img
                                                v-if="this.$i18n.locale === 'fr'"
                                                class="imgFaq"
                                                src="/assets/faq/fr/76.png"
                                            />
                                            <img
                                                v-else
                                                class="imgFaq"
                                                src="/assets/faq/en/76.png"
                                            />
                                        </div>
                                        {{ $t("faq_trouble_15_text2") }}
                                        <br>
                                        {{ $t("faq_trouble_15_text3") }}
                                        <br>
                                        {{ $t("faq_trouble_15_text4") }}
                                        <div class="divImgFaq">
                                            <img
                                                v-if="this.$i18n.locale === 'fr'"
                                                class="imgFaq"
                                                src="/assets/faq/fr/77.png"
                                            />
                                            <img
                                                v-else
                                                class="imgFaq"
                                                src="/assets/faq/en/77.png"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- question-16 -->
                            <div class="card">
                                <div class="card-header" id="headingDeploymentCheckingQuestion16">
                                    <div
                                        class="card-title collapsed"
                                        data-toggle="collapse"
                                        data-target="#collapseFaqDeploymentCheckingQuestion16"
                                        aria-expanded="false"
                                        aria-controls="collapseFaqDeploymentCheckingQuestion16"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            width="24px"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            version="1.1"
                                            class="kt-svg-icon"
                                        >
                                            <g
                                                stroke="none"
                                                stroke-width="1"
                                                fill="none"
                                                fill-rule="evenodd"
                                            >
                                                <polygon
                                                    points="0 0 24 0 24 24 0 24"
                                                ></polygon>
                                                <path
                                                    d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                ></path>
                                                <path
                                                    d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                    opacity="0.3"
                                                    transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                ></path>
                                            </g>
                                        </svg>
                                        {{ $t("faq_trouble_16_title") }}
                                    </div>
                                </div>
                                <div
                                    id="collapseFaqDeploymentCheckingQuestion16"
                                    class="collapse"
                                    aria-labelledby="headingDeploymentCheckingQuestion16"
                                    data-parent="#accordionFaqTrouble"
                                    style=""
                                >
                                    <div class="card-body">
                                        <div class="divImgFaq">
                                            <img
                                                v-if="this.$i18n.locale === 'fr'"
                                                class="imgFaq"
                                                src="/assets/faq/fr/103.png"
                                                id = "img103"
                                            />
                                            <img
                                                v-else
                                                class="imgFaq"
                                                src="/assets/faq/en/103.png"
                                            />
                                        </div>
                                        {{ $t("faq_trouble_16_text1") }}
                                    </div>
                                </div>
                            </div>
                            <!-- question-17 -->
                            <div class="card">
                                <div class="card-header" id="headingConfigurationStatusQuestion17">
                                    <div
                                        class="card-title collapsed"
                                        data-toggle="collapse"
                                        data-target="#collapseFaqConfigurationStatusQuestion17"
                                        aria-expanded="false"
                                        aria-controls="collapseFaqConfigurationStatusQuestion17"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            width="24px"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            version="1.1"
                                            class="kt-svg-icon"
                                        >
                                            <g
                                                stroke="none"
                                                stroke-width="1"
                                                fill="none"
                                                fill-rule="evenodd"
                                            >
                                                <polygon
                                                    points="0 0 24 0 24 24 0 24"
                                                ></polygon>
                                                <path
                                                    d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                ></path>
                                                <path
                                                    d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                    opacity="0.3"
                                                    transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                ></path>
                                            </g>
                                        </svg>
                                        {{ $t("faq_trouble_17_title") }}
                                    </div>
                                </div>
                                <div
                                    id="collapseFaqConfigurationStatusQuestion17"
                                    class="collapse"
                                    aria-labelledby="headingConfigurationStatusQuestion17"
                                    data-parent="#accordionFaqTrouble"
                                    style=""
                                >
                                    <div class="card-body">
                                        {{ $t("faq_trouble_17_text1") }}
                                        <div class="divImgFaq">
                                            <img
                                                v-if="this.$i18n.locale === 'fr'"
                                                class="imgFaq"
                                                src="/assets/faq/fr/104.png"
                                            />
                                            <img
                                                v-else
                                                class="imgFaq"
                                                src="/assets/faq/en/104.png"
                                            />
                                        </div>
                                        <div class="divImgFaq">
                                            <img
                                                v-if="this.$i18n.locale === 'fr'"
                                                class="imgFaq"
                                                src="/assets/faq/fr/105.png"
                                            />
                                            <img
                                                v-else
                                                class="imgFaq"
                                                src="/assets/faq/en/105.png"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- question-18 -->
                            <div class="card">
                                <div class="card-header" id="headingHeatmapStatusQuestion18">
                                    <div
                                        class="card-title collapsed"
                                        data-toggle="collapse"
                                        data-target="#collapseFaqHeatmapStatusQuestion18"
                                        aria-expanded="false"
                                        aria-controls="collapseFaqHeatmapStatusQuestion18"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            width="24px"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            version="1.1"
                                            class="kt-svg-icon"
                                        >
                                            <g
                                                stroke="none"
                                                stroke-width="1"
                                                fill="none"
                                                fill-rule="evenodd"
                                            >
                                                <polygon
                                                    points="0 0 24 0 24 24 0 24"
                                                ></polygon>
                                                <path
                                                    d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                ></path>
                                                <path
                                                    d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                    opacity="0.3"
                                                    transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                ></path>
                                            </g>
                                        </svg>
                                        {{ $t("faq_trouble_18_title") }}
                                    </div>
                                </div>
                                <div
                                    id="collapseFaqHeatmapStatusQuestion18"
                                    class="collapse"
                                    aria-labelledby="headingHeatmapStatusQuestion18"
                                    data-parent="#accordionFaqTrouble"
                                    style=""
                                >
                                    <div class="card-body">
                                        {{ $t("faq_trouble_18_text1") }}
                                        <div class="divImgFaq">
                                            <img
                                                v-if="this.$i18n.locale === 'fr'"
                                                class="imgFaq"
                                                src="/assets/faq/fr/106.png"
                                            />
                                            <img
                                                v-else
                                                class="imgFaq"
                                                src="/assets/faq/en/106.png"
                                            />
                                        </div>
                                        {{ $t("faq_trouble_18_text2") }}
                                        <div class="divImgFaq">
                                            <img
                                                v-if="this.$i18n.locale === 'fr'"
                                                class="imgFaq"
                                                src="/assets/faq/fr/107.png"
                                            />
                                            <img
                                                v-else
                                                class="imgFaq"
                                                src="/assets/faq/en/107.png"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- question-19 -->
                            <div class="card">
                                <div class="card-header" id="headingWallPositionQuestion19">
                                    <div
                                        class="card-title collapsed"
                                        data-toggle="collapse"
                                        data-target="#collapseFaqWallPositionQuestion19"
                                        aria-expanded="false"
                                        aria-controls="collapseFaqWallPositionQuestion19"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            width="24px"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            version="1.1"
                                            class="kt-svg-icon"
                                        >
                                            <g
                                                stroke="none"
                                                stroke-width="1"
                                                fill="none"
                                                fill-rule="evenodd"
                                            >
                                                <polygon
                                                    points="0 0 24 0 24 24 0 24"
                                                ></polygon>
                                                <path
                                                    d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                ></path>
                                                <path
                                                    d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                    opacity="0.3"
                                                    transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                ></path>
                                            </g>
                                        </svg>
                                        {{ $t("faq_trouble_19_title") }}
                                    </div>
                                </div>
                                <div
                                    id="collapseFaqWallPositionQuestion19"
                                    class="collapse"
                                    aria-labelledby="headingWallPositionQuestion19"
                                    data-parent="#accordionFaqTrouble"
                                    style=""
                                >
                                    <div class="card-body">
                                        {{ $t("faq_trouble_19_text1") }}
                                        <div class="divImgFaq">
                                            <img
                                                v-if="this.$i18n.locale === 'fr'"
                                                class="imgFaq"
                                                src="/assets/faq/fr/108.png"
                                            />
                                            <img
                                                v-else
                                                class="imgFaq"
                                                src="/assets/faq/en/108.png"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- question-20 -->
                            <div class="card">
                                <div class="card-header" id="headingIndoorAreaQuestion20">
                                    <div
                                        class="card-title collapsed"
                                        data-toggle="collapse"
                                        data-target="#collapseFaqIndoorAreaQuestion20"
                                        aria-expanded="false"
                                        aria-controls="collapseFaqIndoorAreaQuestion20"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            width="24px"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            version="1.1"
                                            class="kt-svg-icon"
                                        >
                                            <g
                                                stroke="none"
                                                stroke-width="1"
                                                fill="none"
                                                fill-rule="evenodd"
                                            >
                                                <polygon
                                                    points="0 0 24 0 24 24 0 24"
                                                ></polygon>
                                                <path
                                                    d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                ></path>
                                                <path
                                                    d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                    opacity="0.3"
                                                    transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                ></path>
                                            </g>
                                        </svg>
                                        {{ $t("faq_trouble_20_title") }}
                                    </div>
                                </div>
                                <div
                                    id="collapseFaqIndoorAreaQuestion20"
                                    class="collapse"
                                    aria-labelledby="headingIndoorAreaQuestion20"
                                    data-parent="#accordionFaqTrouble"
                                    style=""
                                >
                                    <div class="card-body">
                                        {{ $t("faq_trouble_20_text1") }}
                                        <div class="divImgFaq">
                                            <img
                                                v-if="this.$i18n.locale === 'fr'"
                                                class="imgFaq"
                                                src="/assets/faq/fr/109.png"
                                            />
                                            <img
                                                v-else
                                                class="imgFaq"
                                                src="/assets/faq/en/109.png"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- question-21 -->
                            <div class="card">
                                <div class="card-header" id="headingGeofenceBeaconsQuestion21">
                                    <div
                                        class="card-title collapsed"
                                        data-toggle="collapse"
                                        data-target="#collapseFaqgGeofenceBeaconsQuestion21"
                                        aria-expanded="false"
                                        aria-controls="collapseFaqgGeofenceBeaconsQuestion21"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            width="24px"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            version="1.1"
                                            class="kt-svg-icon"
                                        >
                                            <g
                                                stroke="none"
                                                stroke-width="1"
                                                fill="none"
                                                fill-rule="evenodd"
                                            >
                                                <polygon
                                                    points="0 0 24 0 24 24 0 24"
                                                ></polygon>
                                                <path
                                                    d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                ></path>
                                                <path
                                                    d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                    fill="#000000"
                                                    fill-rule="nonzero"
                                                    opacity="0.3"
                                                    transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                ></path>
                                            </g>
                                        </svg>
                                        {{ $t("faq_trouble_21_title") }}
                                    </div>
                                </div>
                                <div
                                    id="collapseFaqgGeofenceBeaconsQuestion21"
                                    class="collapse"
                                    aria-labelledby="headingGeofenceBeaconsQuestion21"
                                    data-parent="#accordionFaqTrouble"
                                    style=""
                                >
                                    <div class="card-body">
                                        {{ $t("faq_trouble_21_text1") }}
                                        <div class="divImgFaq">
                                            <img
                                                v-if="this.$i18n.locale === 'fr'"
                                                class="imgFaq"
                                                src="/assets/faq/fr/110.png"
                                            />
                                            <img
                                                v-else
                                                class="imgFaq"
                                                src="/assets/faq/en/110.png"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    }
}
</script>

<style>

.images_petit {
    width:100px;
    height:100px;
    margin: 0 auto;
    text-align: center;
    border: 1px solid #dfdee4;
}
</style>