<template>
    <div class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div
                class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page"
            >
                <!-- begin:: home menu -->
                <app-homemenu></app-homemenu>
                <!-- end:: home menu -->

                <div
                    class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
                >
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content -->
                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <div class="kt-portlet kt-sc-faq-2" style="margin-top: -53px;">
                                <div class="kt-portlet__body">
                                    <div class="kt-sc__bg kt-sc__bg--layer">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            version="1.1"
                                            id="Layer_1"
                                            x="0px"
                                            y="0px"
                                            viewBox="0 0 1200 172"
                                            style="enable-background:new 0 0 1200 172;"
                                            xml:space="preserve"
                                            class="kt-svg-icon"
                                        >
                                            <path
                                                class="st0"
                                                d="M2058,94c0,0,16-14,73-14s153,92,248,92s286-145,456-145s183,34,292,34s131-54,131-54v172H2058V94z"
                                            ></path>
                                            <path
                                                class="st0"
                                                d="M0,87c0,0,16-14,73-14s153,92,248,92S607,20,777,20s183,34,292,34s131-54,131-54v172H0V87z"
                                            ></path>
                                        </svg>
                                    </div>
                                    <div class="kt-sc__bg">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            id="e66d77ca-31e8-442d-a5de-b801817c4280"
                                            data-name="Layer 1"
                                            width="1024.7"
                                            height="671.65"
                                            viewBox="0 0 1024.7 671.65"
                                            class="kt-svg-icon"
                                        >
                                            <circle
                                                cx="720.55"
                                                cy="430.76"
                                                r="105"
                                                fill="url(#a374d817-ded2-4154-8f0a-fb69bb0241af)"
                                            ></circle>
                                            <circle
                                                cx="720.55"
                                                cy="430.76"
                                                r="100"
                                                fill="#f5f5f5"
                                            ></circle>
                                            <g opacity="0.5">
                                                <path
                                                    d="M811,482.47a62.52,62.52,0,0,0-64.27,55.82h-18a3.64,3.64,0,0,0-3.64,3.64v6a3.65,3.65,0,0,0,3.64,3.65h18A62.5,62.5,0,1,0,811,482.47Z"
                                                    transform="translate(-87.65 -114.18)"
                                                    fill="url(#fd39efba-8078-41b8-857e-e17431406f47)"
                                                ></path>
                                            </g>
                                            <path
                                                d="M810.19,486.22a58.75,58.75,0,0,0-60.41,52.47H732.36a2.91,2.91,0,0,0-2.91,2.91v6.67a2.91,2.91,0,0,0,2.91,2.92h17.42a58.75,58.75,0,1,0,60.41-65Z"
                                                transform="translate(-87.65 -114.18)"
                                                fill="#fff"
                                            ></path>
                                            <circle
                                                cx="720.55"
                                                cy="430.76"
                                                r="41.25"
                                                fill="#795548"
                                            ></circle>
                                            <path
                                                d="M829.45,524.94s11.77,18.28,8.4,32.5C837.85,557.44,851.3,535.09,829.45,524.94Z"
                                                transform="translate(-87.65 -114.18)"
                                                fill="#fff"
                                                opacity="0.2"
                                            ></path>
                                            <circle
                                                cx="746.8"
                                                cy="453.26"
                                                r="3.75"
                                                fill="#fff"
                                                opacity="0.2"
                                            ></circle>
                                            <rect
                                                x="119.09"
                                                y="182.63"
                                                width="213.27"
                                                height="308.72"
                                                transform="translate(-156.47 -56.04) rotate(-12.75)"
                                                fill="url(#b2bf7fa1-65c2-4695-aeca-af8d6fc55ae3)"
                                            ></rect>
                                            <rect
                                                x="334.38"
                                                y="133.91"
                                                width="213.27"
                                                height="308.72"
                                                transform="translate(-140.4 -9.72) rotate(-12.75)"
                                                fill="url(#a8abeec4-29f5-4a5c-9a38-56f560b9b0a3)"
                                            ></rect>
                                            <rect
                                                x="122.15"
                                                y="186.27"
                                                width="207.97"
                                                height="302.01"
                                                transform="translate(-156.52 -55.94) rotate(-12.75)"
                                                fill="#6c63ff"
                                            ></rect>
                                            <rect
                                                x="336.06"
                                                y="137.86"
                                                width="207.97"
                                                height="302.01"
                                                transform="translate(-140.56 -9.92) rotate(-12.75)"
                                                fill="#f5f5f5"
                                            ></rect>
                                            <rect
                                                x="135.55"
                                                y="222.61"
                                                width="124.88"
                                                height="20.81"
                                                transform="translate(-134.2 -64.73) rotate(-12.75)"
                                                fill="#f5f5f5"
                                            ></rect>
                                            <rect
                                                x="124.12"
                                                y="271.86"
                                                width="166.5"
                                                height="5.2"
                                                transform="translate(-143.12 -61.63) rotate(-12.75)"
                                                fill="#f5f5f5"
                                            ></rect>
                                            <rect
                                                x="126.8"
                                                y="283.7"
                                                width="166.5"
                                                height="5.2"
                                                transform="translate(-145.67 -60.75) rotate(-12.75)"
                                                fill="#f5f5f5"
                                            ></rect>
                                            <rect
                                                x="129.48"
                                                y="295.54"
                                                width="166.5"
                                                height="5.2"
                                                transform="translate(-148.21 -59.87) rotate(-12.75)"
                                                fill="#f5f5f5"
                                            ></rect>
                                            <rect
                                                x="133.23"
                                                y="316.95"
                                                width="79.78"
                                                height="5.2"
                                                transform="translate(-153.92 -68.08) rotate(-12.75)"
                                                fill="#f5f5f5"
                                            ></rect>
                                            <rect
                                                x="135.76"
                                                y="327.46"
                                                width="91.92"
                                                height="5.2"
                                                transform="translate(-156.02 -65.92) rotate(-12.75)"
                                                fill="#f5f5f5"
                                            ></rect>
                                            <rect
                                                x="137.52"
                                                y="331.07"
                                                width="166.5"
                                                height="5.2"
                                                transform="translate(-155.86 -57.21) rotate(-12.75)"
                                                fill="#f5f5f5"
                                            ></rect>
                                            <rect
                                                x="140.2"
                                                y="342.91"
                                                width="166.5"
                                                height="5.2"
                                                transform="translate(-158.4 -56.33) rotate(-12.75)"
                                                fill="#f5f5f5"
                                            ></rect>
                                            <rect
                                                x="143.03"
                                                y="356.09"
                                                width="154.36"
                                                height="5.2"
                                                transform="translate(-161.39 -56.72) rotate(-12.75)"
                                                fill="#f5f5f5"
                                            ></rect>
                                            <rect
                                                x="145.56"
                                                y="366.59"
                                                width="166.5"
                                                height="5.2"
                                                transform="translate(-163.5 -54.56) rotate(-12.75)"
                                                fill="#f5f5f5"
                                            ></rect>
                                            <rect
                                                x="149.22"
                                                y="387.24"
                                                width="86.72"
                                                height="5.2"
                                                transform="translate(-168.95 -62.05) rotate(-12.75)"
                                                fill="#f5f5f5"
                                            ></rect>
                                            <rect
                                                x="372.13"
                                                y="350.43"
                                                width="91.92"
                                                height="5.2"
                                                transform="translate(-155.26 -13.18) rotate(-12.75)"
                                                fill="#6c63ff"
                                                opacity="0.3"
                                            ></rect>
                                            <rect
                                                x="373.89"
                                                y="354.04"
                                                width="166.5"
                                                height="5.2"
                                                transform="translate(-155.1 -4.47) rotate(-12.75)"
                                                fill="#6c63ff"
                                                opacity="0.3"
                                            ></rect>
                                            <rect
                                                x="376.57"
                                                y="365.88"
                                                width="166.5"
                                                height="5.2"
                                                transform="translate(-157.64 -3.59) rotate(-12.75)"
                                                fill="#6c63ff"
                                                opacity="0.3"
                                            ></rect>
                                            <rect
                                                x="379.4"
                                                y="379.06"
                                                width="154.36"
                                                height="5.2"
                                                transform="translate(-160.63 -3.98) rotate(-12.75)"
                                                fill="#6c63ff"
                                                opacity="0.3"
                                            ></rect>
                                            <rect
                                                x="381.93"
                                                y="389.56"
                                                width="166.5"
                                                height="5.2"
                                                transform="translate(-162.74 -1.82) rotate(-12.75)"
                                                fill="#6c63ff"
                                                opacity="0.3"
                                            ></rect>
                                            <rect
                                                x="385.59"
                                                y="410.21"
                                                width="86.72"
                                                height="5.2"
                                                transform="translate(-168.19 -9.31) rotate(-12.75)"
                                                fill="#6c63ff"
                                                opacity="0.3"
                                            ></rect>
                                            <rect
                                                x="221.62"
                                                y="391.43"
                                                width="100.59"
                                                height="69.38"
                                                transform="translate(-175 -43.64) rotate(-12.75)"
                                                fill="#f5f5f5"
                                            ></rect>
                                            <rect
                                                x="337.76"
                                                y="165.98"
                                                width="100.59"
                                                height="69.38"
                                                transform="translate(-122.37 -23.57) rotate(-12.75)"
                                                fill="#6c63ff"
                                                opacity="0.3"
                                            ></rect>
                                            <rect
                                                x="433.79"
                                                y="236.72"
                                                width="100.59"
                                                height="69.38"
                                                transform="translate(-135.62 -0.63) rotate(-12.75)"
                                                fill="#6c63ff"
                                                opacity="0.3"
                                            ></rect>
                                            <rect
                                                x="410.68"
                                                y="203.79"
                                                width="213.27"
                                                height="308.72"
                                                rx="13.44"
                                                transform="translate(-153.95 8.85) rotate(-12.75)"
                                                fill="url(#af3e6cbd-120e-45f3-bb1d-3967e91ae903)"
                                            ></rect>
                                            <rect
                                                x="412.36"
                                                y="207.74"
                                                width="207.97"
                                                height="302.01"
                                                rx="13.44"
                                                transform="translate(-154.1 8.65) rotate(-12.75)"
                                                fill="#6c63ff"
                                            ></rect>
                                            <g opacity="0.5">
                                                <rect
                                                    x="220.35"
                                                    y="542.11"
                                                    width="3"
                                                    height="17"
                                                    fill="#47e6b1"
                                                ></rect>
                                                <rect
                                                    x="308"
                                                    y="656.29"
                                                    width="3"
                                                    height="17"
                                                    transform="translate(886.64 241.11) rotate(90)"
                                                    fill="#47e6b1"
                                                ></rect>
                                            </g>
                                            <g opacity="0.5">
                                                <rect
                                                    x="726.55"
                                                    y="32.76"
                                                    width="3"
                                                    height="17"
                                                    fill="#47e6b1"
                                                ></rect>
                                                <rect
                                                    x="814.2"
                                                    y="146.94"
                                                    width="3"
                                                    height="17"
                                                    transform="translate(883.49 -774.44) rotate(90)"
                                                    fill="#47e6b1"
                                                ></rect>
                                            </g>
                                            <g opacity="0.5">
                                                <rect
                                                    x="636.55"
                                                    y="249.76"
                                                    width="3"
                                                    height="17"
                                                    fill="#47e6b1"
                                                ></rect>
                                                <rect
                                                    x="724.2"
                                                    y="363.94"
                                                    width="3"
                                                    height="17"
                                                    transform="translate(1010.49 -467.44) rotate(90)"
                                                    fill="#47e6b1"
                                                ></rect>
                                            </g>
                                            <g opacity="0.5">
                                                <rect
                                                    x="894.15"
                                                    y="288.76"
                                                    width="3"
                                                    height="17"
                                                    fill="#47e6b1"
                                                ></rect>
                                                <rect
                                                    x="981.8"
                                                    y="402.94"
                                                    width="3"
                                                    height="17"
                                                    transform="translate(1307.08 -686.03) rotate(90)"
                                                    fill="#47e6b1"
                                                ></rect>
                                            </g>
                                            <path
                                                d="M482.69,654.59a3.68,3.68,0,0,1-2.05-4.44,1.15,1.15,0,0,0,.08-.41h0a1.84,1.84,0,0,0-3.31-1.22h0a2.53,2.53,0,0,0-.2.36,3.67,3.67,0,0,1-4.44,2,1.86,1.86,0,0,0-.41-.08h0a1.84,1.84,0,0,0-1.22,3.31h0a1.88,1.88,0,0,0,.36.21,3.67,3.67,0,0,1,2.05,4.44,1.89,1.89,0,0,0-.08.4h0a1.84,1.84,0,0,0,3.31,1.23h0a1.9,1.9,0,0,0,.2-.37,3.68,3.68,0,0,1,4.45-2,1.77,1.77,0,0,0,.4.08h0a1.84,1.84,0,0,0,1.22-3.31h0A1.62,1.62,0,0,0,482.69,654.59Z"
                                                transform="translate(-87.65 -114.18)"
                                                fill="#4d8af0"
                                                opacity="0.5"
                                            ></path>
                                            <path
                                                d="M628.89,779.24a3.68,3.68,0,0,1-2-4.44,1.86,1.86,0,0,0,.08-.41h0a1.84,1.84,0,0,0-3.31-1.22h0a1.82,1.82,0,0,0-.2.36,3.66,3.66,0,0,1-4.44,2.05,2,2,0,0,0-.41-.08h0a1.84,1.84,0,0,0-1.22,3.31h0a1.62,1.62,0,0,0,.36.21,3.68,3.68,0,0,1,2,4.44,1.86,1.86,0,0,0-.08.41h0a1.84,1.84,0,0,0,3.31,1.22h0a1.65,1.65,0,0,0,.2-.37,3.67,3.67,0,0,1,4.44-2,2,2,0,0,0,.41.08h0a1.84,1.84,0,0,0,1.22-3.31h0A1.62,1.62,0,0,0,628.89,779.24Z"
                                                transform="translate(-87.65 -114.18)"
                                                fill="#4d8af0"
                                                opacity="0.5"
                                            ></path>
                                            <path
                                                d="M847.89,348.24a3.68,3.68,0,0,1-2-4.44,1.86,1.86,0,0,0,.08-.41h0a1.84,1.84,0,0,0-3.31-1.22h0a1.82,1.82,0,0,0-.2.36,3.66,3.66,0,0,1-4.44,2.05,2,2,0,0,0-.41-.08h0a1.84,1.84,0,0,0-1.22,3.31h0a1.62,1.62,0,0,0,.36.21,3.68,3.68,0,0,1,2,4.44,1.86,1.86,0,0,0-.08.41h0a1.84,1.84,0,0,0,3.31,1.22h0a1.65,1.65,0,0,0,.2-.37,3.67,3.67,0,0,1,4.44-2,2,2,0,0,0,.41.08h0a1.84,1.84,0,0,0,1.22-3.31h0A1.62,1.62,0,0,0,847.89,348.24Z"
                                                transform="translate(-87.65 -114.18)"
                                                fill="#4d8af0"
                                                opacity="0.5"
                                            ></path>
                                            <path
                                                d="M999.89,509.24a3.68,3.68,0,0,1-2-4.44,1.86,1.86,0,0,0,.08-.41h0a1.84,1.84,0,0,0-3.31-1.22h0a1.82,1.82,0,0,0-.2.36,3.66,3.66,0,0,1-4.44,2.05,2,2,0,0,0-.41-.08h0a1.84,1.84,0,0,0-1.22,3.31h0a1.62,1.62,0,0,0,.36.21,3.68,3.68,0,0,1,2,4.44,1.86,1.86,0,0,0-.08.41h0a1.84,1.84,0,0,0,3.31,1.22h0a1.65,1.65,0,0,0,.2-.37,3.67,3.67,0,0,1,4.44-2,2,2,0,0,0,.41.08h0a1.84,1.84,0,0,0,1.22-3.31h0A1.62,1.62,0,0,0,999.89,509.24Z"
                                                transform="translate(-87.65 -114.18)"
                                                fill="#4d8af0"
                                                opacity="0.5"
                                            ></path>
                                            <circle
                                                cx="142.35"
                                                cy="487.11"
                                                r="6"
                                                fill="#f55f44"
                                                opacity="0.5"
                                            ></circle>
                                            <circle
                                                cx="523.55"
                                                cy="50.76"
                                                r="6"
                                                fill="#4d8af0"
                                                opacity="0.5"
                                            ></circle>
                                            <circle
                                                cx="498.55"
                                                cy="489.76"
                                                r="6"
                                                fill="#47e6b1"
                                                opacity="0.5"
                                            ></circle>
                                            <circle
                                                cx="844.55"
                                                cy="639.76"
                                                r="6"
                                                fill="#f55f44"
                                                opacity="0.5"
                                            ></circle>
                                            <g opacity="0.5">
                                                <path
                                                    d="M848.9,203.3h286a6.29,6.29,0,0,1,6.29,6.29V362.51a6.29,6.29,0,0,1-6.29,6.29h-286a6.28,6.28,0,0,1-6.28-6.28V209.58A6.29,6.29,0,0,1,848.9,203.3Z"
                                                    transform="translate(346.54 1040.57) rotate(-73.39)"
                                                    fill="url(#ad46d94a-2a5e-454e-b2ea-f38dffec9c1a)"
                                                ></path>
                                            </g>
                                            <rect
                                                x="911.41"
                                                y="140.66"
                                                width="161.02"
                                                height="290.64"
                                                rx="13.64"
                                                transform="translate(35.53 -385.86) rotate(16.61)"
                                                fill="#fff"
                                            ></rect>
                                            <path
                                                d="M1070.15,165.27a13,13,0,0,1-15.5,7l-53.29-15.91A13.05,13.05,0,0,1,992.2,142L964,133.59a6.13,6.13,0,0,0-7.61,4.11L880.92,390.53a6.11,6.11,0,0,0,4.12,7.6l134.84,40.24a6.11,6.11,0,0,0,7.6-4.11l75.44-252.83a6.1,6.1,0,0,0-4.11-7.6Z"
                                                transform="translate(-87.65 -114.18)"
                                                fill="#e0e0e0"
                                            ></path>
                                            <rect
                                                x="1010.74"
                                                y="156.59"
                                                width="35.88"
                                                height="2.24"
                                                rx="1.12"
                                                transform="translate(-5.15 -389.83) rotate(15.89)"
                                                fill="#dbdbdb"
                                            ></rect>
                                            <circle
                                                cx="965.68"
                                                cy="50.32"
                                                r="1.35"
                                                fill="#dbdbdb"
                                            ></circle>
                                            <rect
                                                x="953.4"
                                                y="188.74"
                                                width="44"
                                                height="38"
                                                transform="translate(2.54 -365.73) rotate(15.4)"
                                                fill="#fff"
                                            ></rect>
                                            <rect
                                                x="938.79"
                                                y="241.77"
                                                width="44"
                                                height="38"
                                                transform="translate(16.09 -359.95) rotate(15.4)"
                                                fill="#6c63ff"
                                                opacity="0.3"
                                            ></rect>
                                            <rect
                                                x="924.19"
                                                y="294.79"
                                                width="44"
                                                height="38"
                                                transform="translate(29.65 -354.17) rotate(15.4)"
                                                fill="#fff"
                                            ></rect>
                                            <rect
                                                x="909.58"
                                                y="347.82"
                                                width="44"
                                                height="38"
                                                transform="translate(43.2 -348.39) rotate(15.4)"
                                                fill="#3ad29f"
                                                opacity="0.3"
                                            ></rect>
                                            <rect
                                                x="1008.3"
                                                y="214.49"
                                                width="58"
                                                height="4"
                                                transform="translate(7.08 -381.86) rotate(15.4)"
                                                fill="#fff"
                                            ></rect>
                                            <rect
                                                x="1005.11"
                                                y="226.06"
                                                width="58"
                                                height="4"
                                                transform="translate(10.04 -380.6) rotate(15.4)"
                                                fill="#fff"
                                            ></rect>
                                            <rect
                                                x="993.96"
                                                y="266.56"
                                                width="58"
                                                height="4"
                                                transform="translate(20.39 -376.18) rotate(15.4)"
                                                fill="#fff"
                                            ></rect>
                                            <rect
                                                x="990.77"
                                                y="278.12"
                                                width="58"
                                                height="4"
                                                transform="translate(23.35 -374.92) rotate(15.4)"
                                                fill="#fff"
                                            ></rect>
                                            <rect
                                                x="979.62"
                                                y="318.62"
                                                width="58"
                                                height="4"
                                                transform="translate(33.7 -370.5) rotate(15.4)"
                                                fill="#fff"
                                            ></rect>
                                            <rect
                                                x="976.43"
                                                y="330.19"
                                                width="58"
                                                height="4"
                                                transform="translate(36.66 -369.24) rotate(15.4)"
                                                fill="#fff"
                                            ></rect>
                                            <rect
                                                x="965.28"
                                                y="370.68"
                                                width="58"
                                                height="4"
                                                transform="translate(47.01 -364.83) rotate(15.4)"
                                                fill="#fff"
                                            ></rect>
                                            <rect
                                                x="962.09"
                                                y="382.25"
                                                width="58"
                                                height="4"
                                                transform="translate(49.97 -363.56) rotate(15.4)"
                                                fill="#fff"
                                            ></rect>
                                        </svg>
                                    </div>
                                    <div class="kt-sc__top">
                                        <div class="kt-sc__content">
                                            <h2 class="kt-sc__title">
                                                {{ $t("main_faq") }}
                                                <br>
                                                <a v-if="this.releasedFor == 'ALE'" download :href="fileLink" class="btn btn-sm btn-outline-dark btn-bold" style="margin-top: 10px;" id="faq_downloadFAQ">
                                                    <i class="fa fa-file-pdf kt-margin-t-1 kt-margin-r-5"></i>
                                                    {{ $t("common_downloadPDF") }}
                                                </a>
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="kt-portlet kt-portlet--height-fluid">
                                        <div class="kt-portlet__body">
                                            <div class="kt-infobox">
                                                <div class="kt-infobox__header">
                                                    <h2 class="kt-infobox__title">
                                                        {{ $t("faq_homePage") }}
                                                    </h2>
                                                </div>
                                                <div class="kt-infobox__body">
                                                    <div
                                                        class="accordion accordion-light  accordion-svg-icon"
                                                        id="accordionExample1"
                                                    >
                                                        <div class="card">
                                                            <div class="card-header" id="headingOne1">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne1"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne1"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_homePage_1_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne1"
                                                                class="collapse"
                                                                aria-labelledby="headingOne1"
                                                                data-parent="#accordionExample1"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_homePage_1_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne2">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne2"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne2"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_homePage_2_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne2"
                                                                class="collapse"
                                                                aria-labelledby="headingOne2"
                                                                data-parent="#accordionExample1"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_homePage_2_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/1.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/1.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne3">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne3"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne3"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_homePage_3_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne3"
                                                                class="collapse"
                                                                aria-labelledby="headingOne3"
                                                                data-parent="#accordionExample1"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_homePage_3_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="kt-portlet kt-portlet--height-fluid">
                                        <div class="kt-portlet__body">
                                            <div class="kt-infobox">
                                                <div class="kt-infobox__header">
                                                    <h2 class="kt-infobox__title">
                                                        {{ $t("faq_siteOverview") }}
                                                    </h2>
                                                </div>
                                                <div class="kt-infobox__body">
                                                    <div
                                                        class="accordion accordion-light  accordion-svg-icon"
                                                        id="accordionExample20"
                                                    >
                                                        <div class="card">
                                                            <div class="card-header" id="headingOne21">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne21"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne21"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_siteOverview_1_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne21"
                                                                class="collapse"
                                                                aria-labelledby="headingOne21"
                                                                data-parent="#accordionExample20"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_siteOverview_1_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/113.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/113.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card">
                                                            <div class="card-header" id="headingOne22">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne22"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne22"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_siteOverview_2_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne22"
                                                                class="collapse"
                                                                aria-labelledby="headingOne22"
                                                                data-parent="#accordionExample20"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_siteOverview_2_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card">
                                                            <div class="card-header" id="headingOne23">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne23"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne23"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_siteOverview_3_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne23"
                                                                class="collapse"
                                                                aria-labelledby="headingOne23"
                                                                data-parent="#accordionExample20"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_siteOverview_3_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne24">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne24"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne24"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_siteOverview_4_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne24"
                                                                class="collapse"
                                                                aria-labelledby="headingOne24"
                                                                data-parent="#accordionExample20"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_siteOverview_4_text1") }}
                                                                    <ol class="listFaq">
                                                                        <li>
                                                                            {{ $t("faq_siteOverview_4_text2") }}
                                                                        </li>
                                                                        <li>
                                                                            {{ $t("faq_siteOverview_4_text3") }}
                                                                        </li>
                                                                        <li>
                                                                            {{ $t("faq_siteOverview_4_text4") }}
                                                                        </li>
                                                                        <li v-if="this.$i18n.locale === 'fr'">
                                                                            {{ $t("faq_siteOverview_4_text5") }}
                                                                        </li>
                                                                    </ol>
                                                                    <span v-if="this.$i18n.locale === 'fr'">{{ $t("faq_siteOverview_4_text6") }}</span>
                                                                    <span v-else>{{ $t("faq_siteOverview_4_text5") }}</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne25">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne25"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne25"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_siteOverview_5_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne25"
                                                                class="collapse"
                                                                aria-labelledby="headingOne25"
                                                                data-parent="#accordionExample20"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_siteOverview_5_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/2.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/2.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne26">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne26"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne26"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_siteOverview_6_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne26"
                                                                class="collapse"
                                                                aria-labelledby="headingOne26"
                                                                data-parent="#accordionExample20"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_siteOverview_6_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/3.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/3.png"
                                                                        />
                                                                    </div>
                                                                    {{ $t("faq_siteOverview_6_text2") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne27">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne27"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne27"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_siteOverview_7_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne27"
                                                                class="collapse"
                                                                aria-labelledby="headingOne27"
                                                                data-parent="#accordionExample20"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_siteOverview_7_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/114.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/114.png"
                                                                        />
                                                                    </div>
                                                                    {{ $t("faq_siteOverview_7_text2") }}
                                                                    <p>
                                                                        <a :href="helpVideoLink" class="kt-link">{{ $t("faq_siteOverview_7_link1") }}</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="kt-portlet kt-portlet--height-fluid">
                                        <div class="kt-portlet__body">
                                            <div class="kt-infobox">
                                                <div class="kt-infobox__header">
                                                    <h2 class="kt-infobox__title">
                                                        {{ $t("faq_usersRole") }}
                                                    </h2>
                                                </div>
                                                <div class="kt-infobox__body">
                                                    <div
                                                        class="accordion accordion-light  accordion-svg-icon"
                                                        id="accordionExample30"
                                                    >
                                                        <div class="card">
                                                            <div class="card-header" id="headingOne31">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne31"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne31"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_usersRole_1_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne31"
                                                                class="collapse"
                                                                aria-labelledby="headingOne31"
                                                                data-parent="#accordionExample30"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/4.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/4.png"
                                                                        />
                                                                    </div>
                                                                    {{ $t("faq_usersRole_1_text1") }}
                                                                    <div class="divImgFaq" v-if="this.$i18n.locale === 'fr'">
                                                                        <img
                                                                            class="imgFaq"
                                                                            style="border: none"
                                                                            src="/assets/faq/en/115.png"
                                                                        />
                                                                        <img
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/5.png"
                                                                        />
                                                                    </div>
                                                                    <div class="divImgFaq" v-else>
                                                                        <img
                                                                            class="imgFaq"
                                                                            style="border: none"
                                                                            src="/assets/faq/en/115.png"
                                                                        />
                                                                        <img
                                                                            class="imgFaq"
                                                                            style="border: none"
                                                                            src="/assets/faq/en/5.png"
                                                                        />
                                                                    </div>
                                                                    {{ $t("faq_usersRole_1_text2") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne32">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne32"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne32"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_usersRole_2_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne32"
                                                                class="collapse"
                                                                aria-labelledby="headingOne32"
                                                                data-parent="#accordionExample30"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_usersRole_2_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/6.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/6.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne33">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne33"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne33"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_usersRole_3_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne33"
                                                                class="collapse"
                                                                aria-labelledby="headingOne33"
                                                                data-parent="#accordionExample30"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_usersRole_3_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/6.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/6.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne34">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne34"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne34"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_usersRole_4_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne34"
                                                                class="collapse"
                                                                aria-labelledby="headingOne34"
                                                                data-parent="#accordionExample30"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_usersRole_4_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/7.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/7.png"
                                                                        />
                                                                    </div>
                                                                    <ol class="listFaq">
                                                                        <li>{{ $t("faq_usersRole_4_text2") }}</li>
                                                                        <li>{{ $t("faq_usersRole_4_text3") }}</li>
                                                                    </ol>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne35">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne35"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne35"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_usersRole_5_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne35"
                                                                class="collapse"
                                                                aria-labelledby="headingOne35"
                                                                data-parent="#accordionExample30"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_usersRole_5_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/116.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/116.png"
                                                                        />
                                                                    </div>
                                                                    {{ $t("faq_usersRole_5_text2") }}
                                                                    <div class="divImgFaq" v-if="this.$i18n.locale === 'fr'">
                                                                        <img
                                                                            class="imgFaq"
                                                                            style="border: none"
                                                                            src="/assets/faq/fr/117.png"
                                                                        />
                                                                        <img
                                                                            class="imgFaq"
                                                                            style="border: none"
                                                                            src="/assets/faq/fr/8.png"
                                                                        />
                                                                    </div>
                                                                    <div class="divImgFaq" v-else>
                                                                        <img
                                                                            class="imgFaq"
                                                                            style="border: none"
                                                                            src="/assets/faq/en/117.png"
                                                                        />
                                                                        <img
                                                                            class="imgFaq"
                                                                            style="border: none"
                                                                            src="/assets/faq/en/8.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne36">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne36"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne36"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_usersRole_6_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne36"
                                                                class="collapse"
                                                                aria-labelledby="headingOne36"
                                                                data-parent="#accordionExample30"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_usersRole_6_text1") }}
                                                                    <ol class="listFaq" style="list-style: decimal;">
                                                                        <li>
                                                                            {{ $t("faq_usersRole_6_text2") }}
                                                                        </li>
                                                                        {{  $t("faq_usersRole_6_text3") }}
                                                                        <div class="divImgFaq">
                                                                            <img
                                                                                v-if="this.$i18n.locale === 'fr'"
                                                                                class="imgFaq"
                                                                                src="/assets/faq/fr/9.png"
                                                                            />
                                                                            <img
                                                                                v-else
                                                                                class="imgFaq"
                                                                                src="/assets/faq/en/9.png"
                                                                            />
                                                                        </div>
                                                                        <li>
                                                                            {{ $t("faq_usersRole_6_text4") }}
                                                                        </li>
                                                                        {{ $t("faq_usersRole_6_text5") }}
                                                                        <div class="divImgFaq">
                                                                            <img
                                                                                v-if="this.$i18n.locale === 'fr'"
                                                                                class="imgFaq"
                                                                                src="/assets/faq/fr/10.png"
                                                                            />
                                                                            <img
                                                                                v-else
                                                                                class="imgFaq"
                                                                                src="/assets/faq/en/10.png"
                                                                            />
                                                                        </div>
                                                                    </ol>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne37">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne37"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne37"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_usersRole_7_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne37"
                                                                class="collapse"
                                                                aria-labelledby="headingOne37"
                                                                data-parent="#accordionExample30"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne38">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne38"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne38"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_usersRole_8_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne38"
                                                                class="collapse"
                                                                aria-labelledby="headingOne38"
                                                                data-parent="#accordionExample30"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne39">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne39"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne39"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_usersRole_9_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne39"
                                                                class="collapse"
                                                                aria-labelledby="headingOne39"
                                                                data-parent="#accordionExample30"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/11.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/11.png"
                                                                        />
                                                                    </div>
                                                                    {{ $t("faq_usersRole_9_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/12.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/12.png"
                                                                        />
                                                                    </div>
                                                                    {{ $t("faq_usersRole_9_text2") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne310">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne310"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne310"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_usersRole_10_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne310"
                                                                class="collapse"
                                                                aria-labelledby="headingOne310"
                                                                data-parent="#accordionExample30"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_usersRole_10_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/13.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/13.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="kt-portlet kt-portlet--height-fluid">
                                        <div class="kt-portlet__body">
                                            <div class="kt-infobox">
                                                <div class="kt-infobox__header">
                                                    <h2 class="kt-infobox__title">
                                                        {{ $t("faq_sitelocation") }}
                                                    </h2>
                                                </div>
                                                <div class="kt-infobox__body">
                                                    <div
                                                        class="accordion accordion-light  accordion-svg-icon"
                                                        id="accordionExample40"
                                                    >

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqSitelocation1">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqSitelocation1"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqSitelocation1"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_sitelocation_1_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqSitelocation1"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqSitelocation1"
                                                                data-parent="#accordionExample40"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_sitelocation_1_text1") }}
                                                                    <ol class="listFaq">
                                                                        <li>
                                                                            {{ $t("faq_sitelocation_1_text2") }}
                                                                        </li>
                                                                    </ol>
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/14.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/14.png"
                                                                        />
                                                                    </div>
                                                                    <ol class="listFaq">
                                                                        <li>
                                                                            {{ $t("faq_sitelocation_1_text3") }}
                                                                        </li>
                                                                    </ol>
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/15.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/15.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqSitelocation2">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqSitelocation2"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqSitelocation2"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_sitelocation_2_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqSitelocation2"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqSitelocation2"
                                                                data-parent="#accordionExample40"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_sitelocation_2_text") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqSitelocation3">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqSitelocation3"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqSitelocation3"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_sitelocation_3_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqSitelocation3"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqSitelocation3"
                                                                data-parent="#accordionExample40"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_sitelocation_3_text") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq imgScaling100"
                                                                            src="/assets/faq/fr/118.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq imgScaling100"
                                                                            src="/assets/faq/en/118.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqSitelocation4">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqSitelocation4"
                                                                    aria-expanded="false"
                                                                    ria-controls="collapseFaqSitelocation4">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" eight="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_sitelocation_4_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqSitelocation4"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqSitelocation4"
                                                                data-parent="#accordionExample40"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_sitelocation_4_text1") }}
                                                                    <br/>
                                                                    {{ $t("faq_sitelocation_4_text2") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqSitelocation5">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqSitelocation5"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqSitelocation5"
                                                                >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_sitelocation_5_title") }}
                                                                </div>
                                                            </div>
                                                            <div 
                                                                id="collapseFaqSitelocation5"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqSitelocation5"
                                                                data-parent="#accordionExample40" style=""
                                                            >
                                                                <div class="card-body">
                                                                    <span v-html="$t('faq_sitelocation_5_text')"></span>
                                                                    <div class="divImgFaq">
                                                                        <img v-if="this.$i18n.locale === 'fr'" class="imgFaq imgScaling100" src="/assets/faq/fr/97.png"/>
                                                                        <img v-else class="imgFaq imgScaling100" src="/assets/faq/en/97.png"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqSitelocation6">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqSitelocation6"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqSitelocation6"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_sitelocation_6_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqSitelocation6"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqSitelocation6"
                                                                data-parent="#accordionExample40"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_sitelocation_6_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/119.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/119.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqSitelocation7">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqSitelocation7"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqSitelocation7"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_sitelocation_7_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqSitelocation7"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqSitelocation7"
                                                                data-parent="#accordionExample40"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_sitelocation_7_text1") }}
                                                                    <div class="divImgFaq" v-if="this.$i18n.locale === 'fr'">
                                                                        <img
                                                                            class="imgFaq"
                                                                            style="transform: translateY(calc(100% - 16px));"
                                                                            src="/assets/faq/fr/120.png"
                                                                        />
                                                                        <img
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/17.png"
                                                                        />
                                                                    </div>
                                                                    <div class="divImgFaq" v-else>
                                                                        <img
                                                                            class="imgFaq"
                                                                            style="transform: translateY(calc(100% - 16px));"
                                                                            src="/assets/faq/en/120.png"
                                                                        />
                                                                        <img
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/17.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqSitelocation8">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqSitelocation8"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqSitelocation8"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_sitelocation_8_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqSitelocation8"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqSitelocation8"
                                                                data-parent="#accordionExample40"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_sitelocation_8_text") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq imgScaling100"
                                                                            src="/assets/faq/fr/111.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq imgScaling100"
                                                                            src="/assets/faq/en/111.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqSitelocation9">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqSitelocation9"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqSitelocation9"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_sitelocation_9_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqSitelocation9"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqSitelocation9"
                                                                data-parent="#accordionExample40"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_sitelocation_9_text1") }}
                                                                    <ol class="listFaq">
                                                                        <li>
                                                                            {{ $t("faq_sitelocation_9_text2") }}
                                                                        </li>
                                                                        <li>
                                                                            {{ $t("faq_sitelocation_9_text3") }}
                                                                        </li>
                                                                    </ol>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqSitelocation10">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqSitelocation10"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqSitelocation10"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_sitelocation_10_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqSitelocation10"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqSitelocation10"
                                                                data-parent="#accordionExample40"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_sitelocation_10_text") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqSitelocation11">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqSitelocation11"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqSitelocation11"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_sitelocation_11_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqSitelocation11"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqSitelocation11"
                                                                data-parent="#accordionExample40"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_sitelocation_11_text") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq imgScaling100"
                                                                            src="/assets/faq/fr/112.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq imgScaling100"
                                                                            src="/assets/faq/en/112.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="kt-portlet kt-portlet--height-fluid">
                                        <div class="kt-portlet__body">
                                            <div class="kt-infobox">
                                                <div class="kt-infobox__header">
                                                    <h2 class="kt-infobox__title">
                                                        {{ $t("faq_buildingsAndFloors") }}
                                                    </h2>
                                                </div>
                                                <div class="kt-infobox__body">
                                                    <div
                                                        class="accordion accordion-light  accordion-svg-icon"
                                                        id="accordionExample41"
                                                    >
                                                        <div class="card">
                                                            <div class="card-header" id="headingOne42">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne42"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne42"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_buildingsAndFloors_1_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne42"
                                                                class="collapse"
                                                                aria-labelledby="headingOne42"
                                                                data-parent="#accordionExample41"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_buildingsAndFloors_1_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne43">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne43"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne43"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_buildingsAndFloors_2_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne43"
                                                                class="collapse"
                                                                aria-labelledby="headingOne43"
                                                                data-parent="#accordionExample41"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_buildingsAndFloors_2_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne44">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne44"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne44"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_buildingsAndFloors_3_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne44"
                                                                class="collapse"
                                                                aria-labelledby="headingOne44"
                                                                data-parent="#accordionExample41"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    <p>{{ $t("faq_buildingsAndFloors_3_text1") }}</p>
                                                                    <p v-html="$t('faq_buildingsAndFloors_3_text2')"></p>
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/19.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/19.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne45">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne45"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne45"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_buildingsAndFloors_4_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne45"
                                                                class="collapse"
                                                                aria-labelledby="headingOne45"
                                                                data-parent="#accordionExample41"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_buildingsAndFloors_4_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/20.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/20.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="kt-portlet kt-portlet--height-fluid">
                                        <div class="kt-portlet__body">
                                            <div class="kt-infobox">
                                                <div class="kt-infobox__header">
                                                    <h2 class="kt-infobox__title">
                                                        {{ $t("faq_indoorAreas") }}
                                                    </h2>
                                                </div>
                                                <div class="kt-infobox__body">
                                                    <div
                                                        class="accordion accordion-light  accordion-svg-icon"
                                                        id="accordionExample42"
                                                    >
                                                        <div class="card">
                                                            <div class="card-header" id="headingOne46">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne46"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne46"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_indoorAreas_1_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne46"
                                                                class="collapse"
                                                                aria-labelledby="headingOne46"
                                                                data-parent="#accordionExample42"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_indoorAreas_1_text1") }}
                                                                    {{ $t("faq_indoorAreas_1_text2") }}
                                                                    {{ $t("faq_indoorAreas_1_text3") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq imgScaling100"
                                                                            src="/assets/faq/fr/21.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq imgScaling100"
                                                                            src="/assets/faq/en/21.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne47">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne47"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne47"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_indoorAreas_2_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne47"
                                                                class="collapse"
                                                                aria-labelledby="headingOne47"
                                                                data-parent="#accordionExample42"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    <div class="divImgFaq" v-if="this.$i18n.locale === 'fr'">
                                                                        <img
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/121.png"
                                                                        />
                                                                        <img
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/41.png"
                                                                        />
                                                                    </div>
                                                                    <div class="divImgFaq" v-else>
                                                                        <img
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/121.png"
                                                                        />
                                                                        <img
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/41.png"
                                                                        />
                                                                    </div>
                                                                    {{ $t("faq_indoorAreas_2_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card">
                                                            <div class="card-header" id="headingOne48">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne48"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne48"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_indoorAreas_4_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne48"
                                                                class="collapse"
                                                                aria-labelledby="headingOne48"
                                                                data-parent="#accordionExample42"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_indoorAreas_3_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card">
                                                            <div class="card-header" id="headingOne49">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne49"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne49"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_indoorAreas_4_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne49"
                                                                class="collapse"
                                                                aria-labelledby="headingOne49"
                                                                data-parent="#accordionExample42"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/121.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/121.png"
                                                                        />
                                                                    </div>
                                                                    {{ $t("faq_indoorAreas_4_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="kt-portlet kt-portlet--height-fluid">
                                        <div class="kt-portlet__body">
                                            <div class="kt-infobox">
                                                <div class="kt-infobox__header">
                                                    <h2 class="kt-infobox__title">
                                                        {{ $t("faq_walls") }}
                                                    </h2>
                                                </div>
                                                <div class="kt-infobox__body">
                                                    <div
                                                        class="accordion accordion-light  accordion-svg-icon"
                                                        id="accordionFaqWalls"
                                                    >
                                                        <div class="card">
                                                            <div class="card-header" id="headingOne51">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne51"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne51"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_walls_1_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne51"
                                                                class="collapse"
                                                                aria-labelledby="headingOne51"
                                                                data-parent="#accordionFaqWalls"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_walls_1_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card">
                                                            <div class="card-header" id="headingOne52">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne52"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne52"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_walls_2_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne52"
                                                                class="collapse"
                                                                aria-labelledby="headingOne52"
                                                                data-parent="#accordionFaqWalls"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_walls_2_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            style="border: none;"
                                                                            src="/assets/faq/fr/122.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            style="border: none;"
                                                                            src="/assets/faq/en/122.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card">
                                                            <div class="card-header" id="headingOne53">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne53"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne53"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_walls_3_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne53"
                                                                class="collapse"
                                                                aria-labelledby="headingOne53"
                                                                data-parent="#accordionFaqWalls"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_walls_3_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            style="border: none;"
                                                                            src="/assets/faq/fr/123.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            style="border: none;"
                                                                            src="/assets/faq/en/123.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="kt-portlet kt-portlet--height-fluid">
                                        <div class="kt-portlet__body">
                                            <div class="kt-infobox">
                                                <div class="kt-infobox__header">
                                                    <h2 class="kt-infobox__title">
                                                        {{ $t("faq_gateways") }}
                                                    </h2>
                                                </div>
                                                <div class="kt-infobox__body">
                                                    <div
                                                        class="accordion accordion-light  accordion-svg-icon"
                                                        id="accordionFaqBLEGateways"
                                                    >
                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqBLEGateways1">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqBLEGateways1"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqBLEGateways1"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_gateways_1_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqBLEGateways1"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqBLEGateways1"
                                                                data-parent="#accordionFaqBLEGateways"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_gateways_1_text1") }}
                                                                    <ol class="listFaq">
                                                                        <li>{{ $t("faq_gateways_1_text2") }}</li>
                                                                        <li>{{ $t("faq_gateways_1_text3") }}</li>
                                                                        <li>{{ $t("faq_gateways_1_text4") }}</li>
                                                                        <li>{{ $t("faq_gateways_1_text5") }}</li>
                                                                    </ol>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqBLEGateways2">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqBLEGateways2"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqBLEGateways2"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_gateways_2_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqBLEGateways2"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqBLEGateways2"
                                                                data-parent="#accordionFaqBLEGateways"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_gateways_2_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqBLEGateways3">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqBLEGateways3"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqBLEGateways3"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_gateways_3_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqBLEGateways3"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqBLEGateways3"
                                                                data-parent="#accordionFaqBLEGateways"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/124.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/124.png"
                                                                        />
                                                                    </div>
                                                                    {{ $t("faq_gateways_3_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/125.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/125.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqBLEGateways4">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqBLEGateways4"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqBLEGateways4"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_gateways_4_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqBLEGateways4"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqBLEGateways4"
                                                                data-parent="#accordionFaqBLEGateways"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_gateways_4_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/126.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/126.png"
                                                                        />
                                                                    </div>
                                                                    <ol class="listFaq">
                                                                        <li>
                                                                            {{ $t("faq_gateways_4_text2") }}
                                                                        </li>
                                                                        <li>
                                                                            {{ $t("faq_gateways_4_text3") }}
                                                                        </li>
                                                                    </ol>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqBLEGateways5">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqBLEGateways5"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqBLEGateways5"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_gateways_5_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqBLEGateways5"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqBLEGateways5"
                                                                data-parent="#accordionFaqBLEGateways"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_gateways_5_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/24.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/24.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqBLEGateways6">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqBLEGateways6"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqBLEGateways6"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_gateways_6_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqBLEGateways6"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqBLEGateways6"
                                                                data-parent="#accordionFaqBLEGateways"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/127.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/127.png"
                                                                        />
                                                                    </div>
                                                                    {{ $t("faq_gateways_6_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqBLEGateways7">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqBLEGateways7"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqBLEGateways7"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_gateways_7_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqBLEGateways7"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqBLEGateways7"
                                                                data-parent="#accordionFaqBLEGateways"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_gateways_7_text1") }}
                                                                    <div
                                                                        class="divImgFaq"
                                                                        v-if="this.$i18n.locale === 'fr'"
                                                                    >
                                                                        <img
                                                                            class="imgFaq"
                                                                            style="border: none;"
                                                                            src="/assets/faq/fr/128.png"
                                                                        />
                                                                        <img
                                                                            class="imgFaq"
                                                                            style="border: none;"
                                                                            src="/assets/faq/fr/129.png"
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        class="divImgFaq"
                                                                        v-else
                                                                    >
                                                                        <img
                                                                            class="imgFaq"
                                                                            style="border: none;"
                                                                            src="/assets/faq/en/128.png"
                                                                        />
                                                                        <img
                                                                            class="imgFaq"
                                                                            style="border: none;"
                                                                            src="/assets/faq/en/129.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqBLEGateways8">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqBLEGateways8"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqBLEGateways8"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_gateways_8_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqBLEGateways8"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqBLEGateways8"
                                                                data-parent="#accordionFaqBLEGateways"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_gateways_8_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqBLEGateways9">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqBLEGateways9"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqBLEGateways9"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_gateways_9_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqBLEGateways9"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqBLEGateways9"
                                                                data-parent="#accordionFaqBLEGateways"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_gateways_9_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            class="imgFaq"
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            src="/assets/faq/fr/32.png"
                                                                        />
                                                                        <img
                                                                            class="imgFaq"
                                                                            v-else
                                                                            src="/assets/faq/fr/32.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqBLEGateways10">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqBLEGateways10"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqBLEGateways10"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_gateways_10_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqBLEGateways10"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqBLEGateways10"
                                                                data-parent="#accordionFaqBLEGateways"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_gateways_10_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/130.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/130.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqBLEGateways11">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqBLEGateways11"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqBLEGateways11"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_gateways_11_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqBLEGateways11"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqBLEGateways11"
                                                                data-parent="#accordionFaqBLEGateways"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/121.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/121.png"
                                                                        />
                                                                    </div>
                                                                    {{ $t("faq_gateways_11_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqBLEGateways12">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqBLEGateways12"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqBLEGateways12"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_gateways_12_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqBLEGateways12"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqBLEGateways12"
                                                                data-parent="#accordionFaqBLEGateways"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_gateways_12_text1") }}
                                                                    <div class="divImgFaq" v-if="this.$i18n.locale === 'fr'">
                                                                        <img
                                                                            class="imgFaq"
                                                                            style="border: none;"
                                                                            src="/assets/faq/fr/128.png"
                                                                        />
                                                                        <img
                                                                            class="imgFaq"
                                                                            style="border: none;"
                                                                            src="/assets/faq/fr/131.png"
                                                                        />
                                                                    </div>
                                                                    <div class="divImgFaq" v-else>
                                                                        <img
                                                                            class="imgFaq"
                                                                            style="border: none;"
                                                                            src="/assets/faq/en/128.png"
                                                                        />
                                                                        <img
                                                                            class="imgFaq"
                                                                            style="border: none;"
                                                                            src="/assets/faq/en/131.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="kt-portlet kt-portlet--height-fluid">
                                        <div class="kt-portlet__body">
                                            <div class="kt-infobox">
                                                <div class="kt-infobox__header">
                                                    <h2 class="kt-infobox__title">
                                                        {{ $t("faq_tags") }}
                                                    </h2>
                                                </div>
                                                <div class="kt-infobox__body">
                                                    <div
                                                        class="accordion accordion-light  accordion-svg-icon"
                                                        id="accordionFaqTags"
                                                    >
                                                        <div class="card">
                                                            <div class="card-header" id="headingTags1">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseTags1"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseTags1"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_tags_1_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseTags1"
                                                                class="collapse"
                                                                aria-labelledby="headingTags1"
                                                                data-parent="#accordionFaqTags"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_tags_1_text1") }}:
                                                                    <ol class="listFaq">
                                                                        <li>
                                                                            {{ $t("faq_tags_1_text11") }}
                                                                        </li>
                                                                        <li>
                                                                            {{ $t("faq_tags_1_text12") }}
                                                                        </li>
                                                                        <li>
                                                                            {{ $t("faq_tags_1_text13") }}
                                                                        </li>
                                                                    </ol>
                                                                    {{ $t("faq_tags_1_text2") }}:
                                                                    <ol class="listFaq">
                                                                        <li>
                                                                            {{ $t("faq_tags_1_text21") }}
                                                                        </li>
                                                                        <li>
                                                                            {{ $t("faq_tags_1_text22") }}
                                                                        </li>
                                                                        <li>
                                                                            {{ $t("faq_tags_1_text23") }}
                                                                        </li>
                                                                    </ol>
                                                                    {{ $t("faq_tags_1_text3") }}:
                                                                    <ol class="listFaq">
                                                                        <li>
                                                                            {{ $t("faq_tags_1_text31") }}
                                                                        </li>
                                                                        <li>
                                                                            {{ $t("faq_tags_1_text32") }}
                                                                        </li>
                                                                    </ol>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingTags2">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseTags2"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseTags2"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_tags_2_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseTags2"
                                                                class="collapse"
                                                                aria-labelledby="headingTags2"
                                                                data-parent="#accordionFaqTags"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_tags_2_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingTags3">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseTags3"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseTags3"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_tags_3_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseTags3"
                                                                class="collapse"
                                                                aria-labelledby="headingTags3"
                                                                data-parent="#accordionFaqTags"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_tags_3_text1") }}
                                                                    <ol class="listFaq">
                                                                        <li>
                                                                            <span v-html="$t('faq_tags_3_text2')"></span>
                                                                        </li>
                                                                        <li>
                                                                            <span v-html="$t('faq_tags_3_text3')"></span>
                                                                        </li>
                                                                    </ol>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingTags4">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseTags4"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseTags4"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_tags_4_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseTags4"
                                                                class="collapse"
                                                                aria-labelledby="headingTags4"
                                                                data-parent="#accordionFaqTags"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_tags_4_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/132.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/132.png"
                                                                        />
                                                                    </div>
                                                                    {{ $t("faq_tags_4_text2") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingTags5">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseTags5"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseTags5"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_tags_5_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseTags5"
                                                                class="collapse"
                                                                aria-labelledby="headingTags5"
                                                                data-parent="#accordionFaqTags"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/133.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/133.png"
                                                                        />
                                                                    </div>
                                                                    {{ $t("faq_tags_5_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/27.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/27.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingTags6">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseTags6"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseTags6"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_tags_6_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseTags6"
                                                                class="collapse"
                                                                aria-labelledby="headingTags6"
                                                                data-parent="#accordionFaqTags"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_tags_6_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/134.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/134.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingTags7">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseTags7"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseTags7"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_tags_7_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseTags7"
                                                                class="collapse"
                                                                aria-labelledby="headingTags7"
                                                                data-parent="#accordionFaqTags"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_tags_7_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/132.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/132.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingTags8">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseTags8"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseTags8"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_tags_8_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseTags8"
                                                                class="collapse"
                                                                aria-labelledby="headingTags8"
                                                                data-parent="#accordionFaqTags"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_tags_8_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingTags9">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseTags9"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseTags9"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_tags_9_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseTags9"
                                                                class="collapse"
                                                                aria-labelledby="headingTags9"
                                                                data-parent="#accordionFaqTags"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_tags_9_text1") }}
                                                                    <p style="text-decoration: underline">{{ $t("faq_tags_9_text2") }}</p>
                                                                    <ol class="listFaq">
                                                                        <li>
                                                                            {{ $t("faq_tags_9_text3") }}
                                                                        </li>
                                                                    </ol>
                                                                    <p style="text-decoration: underline">{{ $t("faq_tags_9_text4") }}</p>
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/135.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/135.png"
                                                                        />
                                                                    </div>
                                                                    <ol class="listFaq">
                                                                        <li>
                                                                            {{ $t("faq_tags_9_text5") }}
                                                                        </li>
                                                                        <li>
                                                                            {{ $t("faq_tags_9_text6") }}
                                                                        </li>
                                                                    </ol>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingTags10">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseTags10"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseTags10"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_tags_10_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseTags10"
                                                                class="collapse"
                                                                aria-labelledby="headingTags10"
                                                                data-parent="#accordionFaqTags"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_tags_10_text") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/31.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/31.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingTags11">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseTags11"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseTags11"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_tags_11_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseTags11"
                                                                class="collapse"
                                                                aria-labelledby="headingTags11"
                                                                data-parent="#accordionFaqTags"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_tags_11_text") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingTags12">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseTags12"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseTags12"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_tags_12_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseTags12"
                                                                class="collapse"
                                                                aria-labelledby="headingTags12"
                                                                data-parent="#accordionFaqTags"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_tags_12_text") }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="kt-portlet kt-portlet--height-fluid">
                                        <div class="kt-portlet__body">
                                            <div class="kt-infobox">
                                                <div class="kt-infobox__header">
                                                    <h2 class="kt-infobox__title">
                                                        {{ $t("faq_actags") }}
                                                    </h2>
                                                </div>
                                                <div class="kt-infobox__body">
                                                    <div
                                                        class="accordion accordion-light  accordion-svg-icon"
                                                        id="accordionExample44"
                                                    >
                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqActags1">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqActags1"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqActags1"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_actags_1_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqActags1"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqActags1"
                                                                data-parent="#accordionExample44"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_actags_1_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqActags2">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqActags2"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqActags2"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_actags_2_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqActags2"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqActags2"
                                                                data-parent="#accordionExample44"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_actags_2_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqActags3">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqActags3"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqActags3"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_actags_3_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqActags3"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqActags3"
                                                                data-parent="#accordionExample44"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_actags_3_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqActags4">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqActags4"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqActags4"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_actags_4_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqActags4"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqActags4"
                                                                data-parent="#accordionExample44"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_actags_4_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqActags5">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqActags5"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqActags5"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_actags_5_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqActags5"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqActags5"
                                                                data-parent="#accordionExample44"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/136.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/136.png"
                                                                        />
                                                                    </div>
                                                                    {{ $t("faq_actags_5_text1") }}
                                                                    <br>
                                                                    {{ $t("faq_actags_5_text2") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqActags6">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqActags6"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqActags6"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_actags_6_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqActags6"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqActags6"
                                                                data-parent="#accordionExample44"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_actags_6_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqActags7">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqActags7"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqActags7"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_actags_7_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqActags7"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqActags7"
                                                                data-parent="#accordionExample44"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_actags_7_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            style="border: none;"
                                                                            src="/assets/faq/fr/137.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            style="border: none;"
                                                                            src="/assets/faq/en/137.png"
                                                                        />
                                                                    </div>
                                                                    <ol class="listFaq">
                                                                        <li>
                                                                            {{ $t("faq_actags_7_text2") }}
                                                                        </li>
                                                                        <li>
                                                                            {{ $t("faq_actags_7_text3") }}
                                                                        </li>
                                                                    </ol>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqActags8">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqActags8"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqActags8"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_actags_8_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqActags8"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqActags8"
                                                                data-parent="#accordionExample44"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/136.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/136.png"
                                                                        />
                                                                    </div>
                                                                    {{ $t("faq_actags_8_text1") }}
                                                                    <br/>
                                                                    {{ $t("faq_actags_8_text2") }}
                                                                    <div class="divImgFaq" v-if="this.$i18n.locale === 'fr'">
                                                                        <img
                                                                            class="imgFaq"
                                                                            style="border: none;"
                                                                            src="/assets/faq/fr/138.png"
                                                                        />
                                                                        <img
                                                                            class="imgFaq"
                                                                            style="border: none;"
                                                                            src="/assets/faq/fr/139.png"
                                                                        />
                                                                    </div>
                                                                    <div class="divImgFaq" v-else>
                                                                        <img
                                                                            class="imgFaq"
                                                                            style="border: none;"
                                                                            src="/assets/faq/en/138.png"
                                                                        />
                                                                        <img
                                                                            class="imgFaq"
                                                                            style="border: none;"
                                                                            src="/assets/faq/en/139.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqActags9">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqActags9"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqActags9"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_actags_9_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqActags9"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqActags9"
                                                                data-parent="#accordionExample44"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_actags_9_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/140.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/140.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqActags10">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqActags10"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqActags10"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_actags_10_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqActags10"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqActags10"
                                                                data-parent="#accordionExample44"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/121.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/121.png"
                                                                        />
                                                                    </div>
                                                                    {{ $t("faq_actags_10_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqActags11">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqActags11"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqActags11"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_actags_11_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqActags11"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqActags11"
                                                                data-parent="#accordionExample44"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/141.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/141.png"
                                                                        />
                                                                    </div>
                                                                    {{ $t("faq_actags_11_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="kt-portlet kt-portlet--height-fluid">
                                        <div class="kt-portlet__body">
                                            <div class="kt-infobox">
                                                <div class="kt-infobox__header">
                                                    <h2 class="kt-infobox__title">
                                                        {{ $t("faq_asset") }}
                                                    </h2>
                                                </div>
                                                <div class="kt-infobox__body">
                                                    <div
                                                        class="accordion accordion-light  accordion-svg-icon"
                                                        id="accordionFaqAsset"
                                                    >
                                                        <div class="card">
                                                            <div class="card-header" id="headingOne71">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne71"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne71"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_asset_1_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne71"
                                                                class="collapse"
                                                                aria-labelledby="headingOne71"
                                                                data-parent="#accordionFaqAsset"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_asset_1_text1") }}
                                                                    <ol class="listFaq">
                                                                        <li>
                                                                            {{ $t("faq_asset_1_text2") }}
                                                                            <div class="divImgFaq">
                                                                                <img
                                                                                    v-if="this.$i18n.locale === 'fr'"
                                                                                    class="imgFaq"
                                                                                    src="/assets/faq/fr/33.png"
                                                                                />
                                                                                <img
                                                                                    v-else
                                                                                    class="imgFaq"
                                                                                    src="/assets/faq/en/33.png"
                                                                                />
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            {{ $t("faq_asset_1_text3") }}
                                                                            <div class="divImgFaq">
                                                                                <img
                                                                                    v-if="this.$i18n.locale === 'fr'"
                                                                                    class="imgFaq"
                                                                                    src="/assets/faq/fr/34.png"
                                                                                />
                                                                                <img
                                                                                    v-else
                                                                                    class="imgFaq"
                                                                                    src="/assets/faq/en/34.png"
                                                                                />
                                                                            </div>
                                                                        </li>
                                                                    </ol>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne72">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne72"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne72"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_asset_2_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne72"
                                                                class="collapse"
                                                                aria-labelledby="headingOne72"
                                                                data-parent="#accordionFaqAsset"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_asset_2_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne73">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne73"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne73"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_asset_3_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne73"
                                                                class="collapse"
                                                                aria-labelledby="headingOne73"
                                                                data-parent="#accordionFaqAsset"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_asset_3_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/142.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/142.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne74">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne74"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne74"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_asset_4_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne74"
                                                                class="collapse"
                                                                aria-labelledby="headingOne74"
                                                                data-parent="#accordionFaqAsset"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/35.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/35.png"
                                                                        />
                                                                    </div>
                                                                    {{ $t("faq_asset_4_text1") }}
                                                                    <br/>
                                                                    {{ $t("faq_asset_4_text2") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne75">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne75"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne75"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_asset_5_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne75"
                                                                class="collapse"
                                                                aria-labelledby="headingOne75"
                                                                data-parent="#accordionFaqAsset"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_asset_5_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/36.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/36.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne76">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne76"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne76"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_asset_6_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne76"
                                                                class="collapse"
                                                                aria-labelledby="headingOne76"
                                                                data-parent="#accordionFaqAsset"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_asset_6_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/37.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/37.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne77">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne77"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne77"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_asset_7_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne77"
                                                                class="collapse"
                                                                aria-labelledby="headingOne77"
                                                                data-parent="#accordionFaqAsset"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_asset_7_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/38.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/38.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne78">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne78"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne78"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_asset_8_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne78"
                                                                class="collapse"
                                                                aria-labelledby="headingOne78"
                                                                data-parent="#accordionFaqAsset"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_asset_8_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne79">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne79"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne79"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_asset_9_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne79"
                                                                class="collapse"
                                                                aria-labelledby="headingOne79"
                                                                data-parent="#accordionFaqAsset"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/143.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/143.png"
                                                                        />
                                                                    </div>
                                                                    {{ $t("faq_asset_9_text1") }}
                                                                    <br/>
                                                                    {{ $t("faq_asset_9_text2") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/39.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/39.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne710">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne710"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne710"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_asset_10_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne710"
                                                                class="collapse"
                                                                aria-labelledby="headingOne710"
                                                                data-parent="#accordionFaqAsset"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_asset_10_text1") }}
                                                                    <br/>
                                                                    <span><i>{{ $t("faq_asset_10_text2") }}</i></span>
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/144.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/144.png"
                                                                        />
                                                                    </div>
                                                                    {{ $t("faq_asset_10_text3") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne711">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne711"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne711"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_asset_11_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne711"
                                                                class="collapse"
                                                                aria-labelledby="headingOne711"
                                                                data-parent="#accordionFaqAsset"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_asset_11_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingAssetStatus">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseAssetStatus"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseAssetStatus"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_asset_12_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseAssetStatus"
                                                                class="collapse"
                                                                aria-labelledby="headingAssetStatus"
                                                                data-parent="#accordionFaqAsset"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_asset_12_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/102.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/102.png"
                                                                        />
                                                                    </div>
                                                                    {{ $t("faq_asset_12_text2") }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="kt-portlet kt-portlet--height-fluid">
                                        <div class="kt-portlet__body">
                                            <div class="kt-infobox">
                                                <div class="kt-infobox__header">
                                                    <h2 class="kt-infobox__title">
                                                        {{ $t("faq_geofencing") }}
                                                    </h2>
                                                </div>
                                                <div class="kt-infobox__body">
                                                    <div
                                                        class="accordion accordion-light  accordion-svg-icon"
                                                        id="accordionFaqGeofencing"
                                                    >
                                                        <div class="card">
                                                            <div class="card-header" id="headingOne80">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne80"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne80"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_geofencing_1_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne80"
                                                                class="collapse"
                                                                aria-labelledby="headingOne80"
                                                                data-parent="#accordionFaqGeofencing"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/145.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/145.png"
                                                                        />
                                                                    </div>
                                                                    {{ $t("faq_geofencing_1_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne81">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne81"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne81"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_geofencing_2_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne81"
                                                                class="collapse"
                                                                aria-labelledby="headingOne81"
                                                                data-parent="#accordionFaqGeofencing"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/146.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/146.png"
                                                                        />
                                                                    </div>
                                                                    {{ $t("faq_geofencing_2_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne82">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne82"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne82"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_geofencing_3_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne82"
                                                                class="collapse"
                                                                aria-labelledby="headingOne82"
                                                                data-parent="#accordionFaqGeofencing"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_geofencing_3_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/130.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/130.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne83">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne83"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne83"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_geofencing_4_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne83"
                                                                class="collapse"
                                                                aria-labelledby="headingOne83"
                                                                data-parent="#accordionFaqGeofencing"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_geofencing_4_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne810">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne810"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne810"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_geofencing_5_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne810"
                                                                class="collapse"
                                                                aria-labelledby="headingOne810"
                                                                data-parent="#accordionFaqGeofencing"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/42.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/42.png"
                                                                        />
                                                                    </div>
                                                                    {{ $t("faq_geofencing_5_text1") }}
                                                                    <br/>
                                                                    {{ $t("faq_geofencing_5_text2") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/147.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/147.png"
                                                                        />
                                                                    </div>
                                                                    {{ $t("faq_geofencing_5_text3") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne811">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne811"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne811"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_geofencing_6_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne811"
                                                                class="collapse"
                                                                aria-labelledby="headingOne811"
                                                                data-parent="#accordionFaqGeofencing"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_geofencing_6_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/148.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/148.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne812">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne812"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne812"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_geofencing_7_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne812"
                                                                class="collapse"
                                                                aria-labelledby="headingOne812"
                                                                data-parent="#accordionFaqGeofencing"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_geofencing_7_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/149.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/149.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne813">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne813"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne813"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_geofencing_8_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne813"
                                                                class="collapse"
                                                                aria-labelledby="headingOne813"
                                                                data-parent="#accordionFaqGeofencing"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_geofencing_8_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne814">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne814"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne814"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_geofencing_9_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne814"
                                                                class="collapse"
                                                                aria-labelledby="headingOne814"
                                                                data-parent="#accordionFaqGeofencing"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/150.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/150.png"
                                                                        />
                                                                    </div>
                                                                    {{ $t("faq_geofencing_9_text1") }}
                                                                    <br/>
                                                                    {{ $t("faq_geofencing_9_text2") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/151.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/151.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne815">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne815"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne815"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_geofencing_10_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne815"
                                                                class="collapse"
                                                                aria-labelledby="headingOne815"
                                                                data-parent="#accordionFaqGeofencing"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_geofencing_10_text1") }}
                                                                    <ol class="listFaq">
                                                                        <li>
                                                                            {{ $t("faq_geofencing_10_text2") }}
                                                                        </li>
                                                                    </ol>
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/152.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/152.png"
                                                                        />
                                                                    </div>
                                                                    <ol class="listFaq">
                                                                        <li>
                                                                            {{ $t("faq_geofencing_10_text3") }}
                                                                        </li>
                                                                    </ol>
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/153.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/153.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne816">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne816"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne816"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_geofencing_11_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne816"
                                                                class="collapse"
                                                                aria-labelledby="headingOne816"
                                                                data-parent="#accordionFaqGeofencing"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_geofencing_11_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="kt-portlet kt-portlet--height-fluid">
                                        <div class="kt-portlet__body">
                                            <div class="kt-infobox">
                                                <div class="kt-infobox__header">
                                                    <h2 class="kt-infobox__title">
                                                        {{ $t("faq_search") }}
                                                    </h2>
                                                </div>
                                                <div class="kt-infobox__body">
                                                    <div
                                                        class="accordion accordion-light  accordion-svg-icon"
                                                        id="accordionExample45"
                                                    >
                                                        <div class="card">
                                                            <div class="card-header" id="headingOne84">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne84"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne84"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_search_1_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne84"
                                                                class="collapse"
                                                                aria-labelledby="headingOne84"
                                                                data-parent="#accordionExample45"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/154.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/154.png"
                                                                        />
                                                                    </div>
                                                                    {{ $t("faq_search_1_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/43.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/43.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingSearch1">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseSearch1"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseSearch1"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_search_12_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseSearch1"
                                                                class="collapse"
                                                                aria-labelledby="headingSearch1"
                                                                data-parent="#accordionExample45"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_search_12_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne85">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne85"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne85"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_search_2_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne85"
                                                                class="collapse"
                                                                aria-labelledby="headingOne85"
                                                                data-parent="#accordionExample45"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/44.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/44.png"
                                                                        />
                                                                    </div>
                                                                    {{ $t("faq_search_2_text1") }}
                                                                    <br/>
                                                                    {{ $t("faq_search_2_text2") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingSearch2">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseSearch2"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseSearch2"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_search_13_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseSearch2"
                                                                class="collapse"
                                                                aria-labelledby="headingSearch2"
                                                                data-parent="#accordionExample45"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_search_13_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/155.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/155.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingSearch3">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseSearch3"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseSearch3"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_search_14_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseSearch3"
                                                                class="collapse"
                                                                aria-labelledby="headingSearch3"
                                                                data-parent="#accordionExample45"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_search_14_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/157.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/157.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne86">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne86"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne86"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_search_3_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne86"
                                                                class="collapse"
                                                                aria-labelledby="headingOne86"
                                                                data-parent="#accordionExample45"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_search_3_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/165.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/165.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne87">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne87"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne87"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_search_4_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne87"
                                                                class="collapse"
                                                                aria-labelledby="headingOne87"
                                                                data-parent="#accordionExample45"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_search_4_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/166.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/166.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne88">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne88"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne88"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_search_5_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne88"
                                                                class="collapse"
                                                                aria-labelledby="headingOne88"
                                                                data-parent="#accordionExample45"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_search_5_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/167.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/167.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne89">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne89"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne89"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_search_6_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne89"
                                                                class="collapse"
                                                                aria-labelledby="headingOne89"
                                                                data-parent="#accordionExample45"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_search_6_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne90">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne90"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne90"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_search_7_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne90"
                                                                class="collapse"
                                                                aria-labelledby="headingOne90"
                                                                data-parent="#accordionExample45"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_search_7_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/48.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/48.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne91">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne91"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne91"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_search_8_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne91"
                                                                class="collapse"
                                                                aria-labelledby="headingOne91"
                                                                data-parent="#accordionExample45"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_search_8_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/49.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/49.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne92">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne92"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne92"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_search_9_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne92"
                                                                class="collapse"
                                                                aria-labelledby="headingOne92"
                                                                data-parent="#accordionExample45"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_search_9_text1") }}
                                                                    <br/>
                                                                    {{ $t("faq_search_9_text2") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/168.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/168.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne93">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne93"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne93"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_search_10_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne93"
                                                                class="collapse"
                                                                aria-labelledby="headingOne93"
                                                                data-parent="#accordionExample45"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_search_10_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/51.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/51.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingGenerateLink">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseGenerateLink"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseGenerateLink"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_search_11_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseGenerateLink"
                                                                class="collapse"
                                                                aria-labelledby="headingGenerateLink"
                                                                data-parent="#accordionExample45"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_search_11_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/101.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/101.png"
                                                                        />
                                                                    </div>
                                                                    <span v-html="$t('faq_search_11_text2')"></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="kt-portlet kt-portlet--height-fluid">
                                        <div class="kt-portlet__body">
                                            <div class="kt-infobox">
                                                <div class="kt-infobox__header">
                                                    <h2 class="kt-infobox__title">{{ $t("faq_sub") }}</h2>
                                                </div>
                                                <div class="kt-infobox__body">
                                                    <div
                                                        class="accordion accordion-light  accordion-svg-icon"
                                                        id="accordionFaqSub"
                                                    >
                                                        <div class="card">
                                                            <div class="card-header" id="headingOne94">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne94"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne94"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_sub_1_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne94"
                                                                class="collapse"
                                                                aria-labelledby="headingOne94"
                                                                data-parent="#accordionFaqSub"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/158.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/158.png"
                                                                        />
                                                                    </div>
                                                                    {{ $t("faq_sub_1_text1") }}
                                                                    <br />
                                                                    {{ $t("faq_sub_1_text2") }}
                                                                    <ol class="listFaq">
                                                                        <li>
                                                                            {{ $t("faq_sub_1_text3") }}
                                                                        </li>
                                                                        <li>
                                                                            {{ $t("faq_sub_1_text4") }}
                                                                        </li>
                                                                    </ol>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne95">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne95"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne95"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_sub_2_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne95"
                                                                class="collapse"
                                                                aria-labelledby="headingOne95"
                                                                data-parent="#accordionFaqSub"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/52.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/52.png"
                                                                        />
                                                                    </div>
                                                                    {{ $t("faq_sub_2_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/159.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/159.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne96">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne96"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne96"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_sub_3_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne96"
                                                                class="collapse"
                                                                aria-labelledby="headingOne96"
                                                                data-parent="#accordionFaqSub"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_sub_3_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne97">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne97"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne97"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_sub_4_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne97"
                                                                class="collapse"
                                                                aria-labelledby="headingOne97"
                                                                data-parent="#accordionFaqSub"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/160.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/160.png"
                                                                        />
                                                                    </div>
                                                                    {{ $t("faq_sub_4_text1") }}
                                                                    <br/>
                                                                    {{ $t("faq_sub_4_text2") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne98">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne98"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne98"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_sub_5_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne98"
                                                                class="collapse"
                                                                aria-labelledby="headingOne98"
                                                                data-parent="#accordionFaqSub"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_sub_5_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne99">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne99"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne99"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_sub_6_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne99"
                                                                class="collapse"
                                                                aria-labelledby="headingOne99"
                                                                data-parent="#accordionFaqSub"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_sub_6_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/53.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/53.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne100">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne100"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne100"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_sub_7_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne100"
                                                                class="collapse"
                                                                aria-labelledby="headingOne100"
                                                                data-parent="#accordionFaqSub"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_sub_7_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne101">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne101"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne101"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_sub_8_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne101"
                                                                class="collapse"
                                                                aria-labelledby="headingOne101"
                                                                data-parent="#accordionFaqSub"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_sub_8_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne102">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne102"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne102"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_sub_9_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne102"
                                                                class="collapse"
                                                                aria-labelledby="headingOne102"
                                                                data-parent="#accordionFaqSub"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_sub_9_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingOne103">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne103"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne103"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_sub_10_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne103"
                                                                class="collapse"
                                                                aria-labelledby="headingOne103"
                                                                data-parent="#accordionFaqSub"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_sub_10_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="kt-portlet kt-portlet--height-fluid">
                                        <div class="kt-portlet__body">
                                            <div class="kt-infobox">
                                                <div class="kt-infobox__header">
                                                    <h2 class="kt-infobox__title">
                                                        {{ $t("faq_analytics") }}
                                                    </h2>
                                                </div>
                                                <div class="kt-infobox__body">
                                                    <div
                                                        class="accordion accordion-light  accordion-svg-icon"
                                                        id="accordionFaqAnalytics"
                                                    >
                                                        <div class="card">
                                                            <div class="card-header" id="headingOne104">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne104"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne104"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_analytics_1_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne104"
                                                                class="collapse"
                                                                aria-labelledby="headingOne84"
                                                                data-parent="#accordionFaqAnalytics"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/54.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/54.png"
                                                                        />
                                                                    </div>
                                                                    {{ $t("faq_analytics_1_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/55.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/55.png"
                                                                        />
                                                                    </div>
                                                                    {{ $t("faq_analytics_1_text2") }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card">
                                                            <div class="card-header" id="headingOne105">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne105"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne105"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_analytics_2_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne105"
                                                                class="collapse"
                                                                aria-labelledby="headingOne84"
                                                                data-parent="#accordionFaqAnalytics"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_analytics_2_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/56.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/56.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card">
                                                            <div class="card-header" id="headingOne106">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne106"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne106"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_analytics_3_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne106"
                                                                class="collapse"
                                                                aria-labelledby="headingOne84"
                                                                data-parent="#accordionFaqAnalytics"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_analytics_3_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/57.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/57.png"
                                                                        />
                                                                    </div>
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/58.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/58.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card">
                                                            <div class="card-header" id="headingOne107">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne107"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne107"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_analytics_4_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne107"
                                                                class="collapse"
                                                                aria-labelledby="headingOne84"
                                                                data-parent="#accordionFaqAnalytics"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_analytics_4_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/59.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/59.png"
                                                                        />
                                                                    </div>
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/60.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/60.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card">
                                                            <div class="card-header" id="headingOne108">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne108"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne108"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_analytics_5_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne108"
                                                                class="collapse"
                                                                aria-labelledby="headingOne84"
                                                                data-parent="#accordionFaqAnalytics"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_analytics_5_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/61.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/61.png"
                                                                        />
                                                                    </div>
                                                                    {{ $t("faq_analytics_5_text2") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/62.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/62.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card">
                                                            <div class="card-header" id="headingOne109">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne109"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne109"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_analytics_6_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne109"
                                                                class="collapse"
                                                                aria-labelledby="headingOne84"
                                                                data-parent="#accordionFaqAnalytics"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_analytics_6_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/61.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/61.png"
                                                                        />
                                                                    </div>
                                                                    {{ $t("faq_analytics_6_text2") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/62.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/62.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card">
                                                            <div class="card-header" id="headingOne110">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne110"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne110"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_analytics_7_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne110"
                                                                class="collapse"
                                                                aria-labelledby="headingOne84"
                                                                data-parent="#accordionFaqAnalytics"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_analytics_7_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/61.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/61.png"
                                                                        />
                                                                    </div>
                                                                    {{ $t("faq_analytics_7_text2") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/62.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/62.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card">
                                                            <div class="card-header" id="headingOne111">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseOne111"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseOne111"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_analytics_8_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseOne111"
                                                                class="collapse"
                                                                aria-labelledby="headingOne84"
                                                                data-parent="#accordionFaqAnalytics"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_analytics_8_text1") }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqAnalytics9">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqAnalytics9"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqAnalytics9"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_analytics_9_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqAnalytics9"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqAnalytics9"
                                                                data-parent="#accordionFaqAnalytics"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_analytics_9_text") }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqAnalytics10">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqAnalytics10"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqAnalytics10"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_analytics_10_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqAnalytics10"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqAnalytics10"
                                                                data-parent="#accordionFaqAnalytics"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_analytics_10_text") }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <!-- Section of troubleshooting faq -->
                                <app-troubleshootingfaq></app-troubleshootingfaq>
                            </div>

                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="kt-portlet kt-portlet--height-fluid">
                                        <div class="kt-portlet__body">
                                            <div class="kt-infobox">
                                                <div class="kt-infobox__header">
                                                    <h2 class="kt-infobox__title">
                                                        {{ $t("faq_mobile") }}
                                                    </h2>
                                                </div>
                                                <div class="kt-infobox__body">
                                                    <div
                                                        class="accordion accordion-light  accordion-svg-icon"
                                                        id="accordionFaqMobile"
                                                    >
                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqMobile1">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqMobile1"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqMobile1"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_mobile_1_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqMobile1"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqMobile1"
                                                                data-parent="#accordionFaqMobile"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_mobile_1_text") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqMobile2">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqMobile2"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqMobile2"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_mobile_2_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqMobile2"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqMobile2"
                                                                data-parent="#accordionFaqMobile"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    <span v-html="$t('faq_mobile_2_text')"></span>
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            class="imgFaq"
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            src="/assets/faq/fr/161.png"
                                                                        />
                                                                        <img
                                                                            class="imgFaq"
                                                                            v-else
                                                                            src="/assets/faq/en/161.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqMobile3">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqMobile3"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqMobile3"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_mobile_3_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqMobile3"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqMobile3"
                                                                data-parent="#accordionFaqMobile"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_mobile_3_text") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqMobile4">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqMobile4"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqMobile4"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_mobile_4_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqMobile4"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqMobile4"
                                                                data-parent="#accordionFaqMobile"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_mobile_4_text") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqMobile5">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqMobile5"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqMobile5"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_mobile_5_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqMobile5"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqMobile5"
                                                                data-parent="#accordionFaqMobile"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_mobile_5_text") }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="kt-portlet kt-portlet--height-fluid">
                                        <div class="kt-portlet__body">
                                            <div class="kt-infobox">
                                                <div class="kt-infobox__header">
                                                    <h2 class="kt-infobox__title">
                                                        {{ $t("faq_api") }}
                                                    </h2>
                                                </div>
                                                <div class="kt-infobox__body">
                                                    <div
                                                        class="accordion accordion-light  accordion-svg-icon"
                                                        id="accordionFaqApi"
                                                    >
                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqApi1">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqApi1"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqApi1"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_api_1_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqApi1"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqApi1"
                                                                data-parent="#accordionFaqApi"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    <span v-html="$t('faq_api_1_text')"></span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqApi2">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqApi2"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqApi2"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_api_2_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqApi2"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqApi2"
                                                                data-parent="#accordionFaqApi"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_api_2_text1") }}
                                                                    <a href="https://web.omniaccess-stellar-asset-tracking.com/apidoc/" target="_blank" class="kt-link"> {{ $t("faq_api_2_text2") }}</a>
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/162.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/162.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="kt-portlet kt-portlet--height-fluid">
                                        <div class="kt-portlet__body">
                                            <div class="kt-infobox">
                                                <div class="kt-infobox__header">
                                                    <h2 class="kt-infobox__title">
                                                        {{ $t("faq_report") }}
                                                    </h2>
                                                </div>
                                                <div class="kt-infobox__body">
                                                    <div
                                                        class="accordion accordion-light  accordion-svg-icon"
                                                        id="accordionFaqReport"
                                                    >
                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqReport1">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqReport1"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqReport1"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_report_1_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqReport1"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqReport1"
                                                                data-parent="#accordionFaqReport"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_report_1_text") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqReport2">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqReport2"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqReport2"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_report_2_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqReport2"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqReport2"
                                                                data-parent="#accordionFaqReport"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_report_2_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/79.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/79.png"
                                                                        />
                                                                    </div>
                                                                    {{ $t("faq_report_2_text2") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqReport3">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqReport3"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqReport3"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_report_3_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqReport3"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqReport3"
                                                                data-parent="#accordionFaqReport"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_report_3_text") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/163.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/163.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqReport4">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqReport4"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqReport4"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_report_4_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqReport4"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqReport4"
                                                                data-parent="#accordionFaqReport"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_report_4_text") }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="kt-portlet kt-portlet--height-fluid">
                                        <div class="kt-portlet__body">
                                            <div class="kt-infobox">
                                                <div class="kt-infobox__header">
                                                    <h2 class="kt-infobox__title">
                                                        {{ $t("faq_location_history") }}
                                                    </h2>
                                                </div>
                                                <div class="kt-infobox__body">
                                                    <div
                                                        class="accordion accordion-light  accordion-svg-icon"
                                                        id="accordionFaqLocationHistory"
                                                    >
                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqLocationHistory1">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqLocationHistory1"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqLocationHistory1"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_location_history_1_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqLocationHistory1"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqLocationHistory1"
                                                                data-parent="#accordionFaqLocationHistory"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_location_history_1_text") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/80.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/80.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqLocationHistory2">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqLocationHistory2"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqLocationHistory2"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_location_history_2_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqLocationHistory2"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqLocationHistory2"
                                                                data-parent="#accordionFaqLocationHistory"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_location_history_2_text") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqLocationHistory3">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqLocationHistory3"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqLocationHistory3"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_location_history_3_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqLocationHistory3"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqLocationHistory3"
                                                                data-parent="#accordionFaqLocationHistory"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_location_history_3_text") }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="kt-portlet kt-portlet--height-fluid">
                                        <div class="kt-portlet__body">
                                            <div class="kt-infobox">
                                                <div class="kt-infobox__header">
                                                    <h2 class="kt-infobox__title">
                                                        {{ $t("faq_contact") }}
                                                    </h2>
                                                </div>
                                                <div class="kt-infobox__body">
                                                    <div
                                                        class="accordion accordion-light  accordion-svg-icon"
                                                        id="accordionFaqContact"
                                                    >
                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqContact1">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqContact1"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqContact1"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_contact_1_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqContact1"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqContact1"
                                                                data-parent="#accordionFaqContact"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_contact_1_text") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqContact2">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqContact2"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqContact2"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_contact_2_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqContact2"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqContact2"
                                                                data-parent="#accordionFaqContact"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_contact_2_text") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqContact3">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqContact3"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqContact3"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_contact_3_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqContact3"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqContact3"
                                                                data-parent="#accordionFaqContact"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    <span v-html="$t('faq_contact_3_text')"></span>
                                                                    <div class="divImgFaq">
                                                                        <img v-if="this.$i18n.locale === 'fr'" class="imgFaq" src="/assets/faq/fr/98.png"/>
                                                                        <img v-else class="imgFaq" src="/assets/faq/en/98.png"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqContact4">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqContact4"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqContact4"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_contact_4_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqContact4"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqContact4"
                                                                data-parent="#accordionFaqContact"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_contact_4_text") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqContact5">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqContact5"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqContact5"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_contact_5_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqContact5"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqContact5"
                                                                data-parent="#accordionFaqContact"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_contact_5_text") }}
                                                                    <div class="divImgFaq">
                                                                        <img v-if="this.$i18n.locale === 'fr'" class="imgFaq" src="/assets/faq/fr/99.png"/>
                                                                        <img v-else class="imgFaq" src="/assets/faq/en/99.png"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqContact6">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqContact6"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqContact6"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_contact_6_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqContact6"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqContact6"
                                                                data-parent="#accordionFaqContact"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_contact_6_text") }}
                                                                    <div class="divImgFaq">
                                                                        <img v-if="this.$i18n.locale === 'fr'" class="imgFaq" src="/assets/faq/fr/100.png"/>
                                                                        <img v-else class="imgFaq" src="/assets/faq/en/100.png"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="kt-portlet kt-portlet--height-fluid">
                                        <div class="kt-portlet__body">
                                            <div class="kt-infobox">
                                                <div class="kt-infobox__header">
                                                    <h2 class="kt-infobox__title">
                                                        {{ $t("faq_gdpr") }}
                                                    </h2>
                                                </div>
                                                <div class="kt-infobox__body">
                                                    <div
                                                        class="accordion accordion-light  accordion-svg-icon"
                                                        id="accordionFaqGdpr"
                                                    >
                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqGdpr1">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqGdpr1"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqGdpr1"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_gdpr_1_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqGdpr1"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqGdpr1"
                                                                data-parent="#accordionFaqGdpr"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_gdpr_1_text") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqGdpr2">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqGdpr2"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqGdpr2"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_gdpr_2_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqGdpr2"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqGdpr2"
                                                                data-parent="#accordionFaqGdpr"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_gdpr_2_text") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/164.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/164.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Push button alert-->
                                <div class="col-lg-6">
                                    <div class="kt-portlet kt-portlet--height-fluid">
                                        <div class="kt-portlet__body">
                                            <div class="kt-infobox">
                                                <div class="kt-infobox__header">
                                                    <h2 class="kt-infobox__title">
                                                        {{ $t("faq_pushButton") }}
                                                    </h2>
                                                </div>
                                                <div class="kt-infobox__body">
                                                    <div
                                                        class="accordion accordion-light  accordion-svg-icon"
                                                        id="accordionFaqPushButtonAlert"
                                                    >
                                                        <!-- Question 1 -->
                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqPushButtonAlert1">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqPushButtonAlert1"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqPushButtonAlert1"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_pushButton_1_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqPushButtonAlert1"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqPushButtonAlert1"
                                                                data-parent="#accordionFaqPushButtonAlert"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_pushButton_1_text") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- Question 2 -->
                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqPushButtonAlert2">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqPushButtonAlert2"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqPushButtonAlert2"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_pushButton_2_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqPushButtonAlert2"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqPushButtonAlert2"
                                                                data-parent="#accordionFaqPushButtonAlert"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_pushButton_2_text1") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/82.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/82.png"
                                                                        />
                                                                        <br>
                                                                    </div>
                                                                    <ol class="listFaq">
                                                                        <li><span v-html="$t('faq_pushButton_2_text2')"></span></li>
                                                                        <li><span v-html="$t('faq_pushButton_2_text3')"></span></li>
                                                                        <li><span v-html="$t('faq_pushButton_2_text4')"></span></li>
                                                                    </ol>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- Question 3 -->
                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqPushButtonAlert3">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqPushButtonAlert3"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqPushButtonAlert3"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_pushButton_3_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqPushButtonAlert3"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqPushButtonAlert3"
                                                                data-parent="#accordionFaqPushButtonAlert"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_pushButton_3_text") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/83.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/83.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- Question 4 -->
                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqPushButtonAlert4">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqPushButtonAlert4"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqPushButtonAlert4"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_pushButton_4_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqPushButtonAlert4"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqPushButtonAlert4"
                                                                data-parent="#accordionFaqPushButtonAlert"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_pushButton_4_text") }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- Question 5 -->
                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqPushButtonAlert5">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqPushButtonAlert5"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqPushButtonAlert5"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_pushButton_5_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqPushButtonAlert5"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqPushButtonAlert5"
                                                                data-parent="#accordionFaqPushButtonAlert"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_pushButton_5_text") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/84.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/84.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- Question 6 -->
                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqPushButtonAlert6">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqPushButtonAlert6"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqPushButtonAlert6"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_pushButton_6_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqPushButtonAlert6"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqPushButtonAlert6"
                                                                data-parent="#accordionFaqPushButtonAlert"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_pushButton_6_text") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/85.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/85.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- Question 7 -->
                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqPushButtonAlert7">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqPushButtonAlert7"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqPushButtonAlert7"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_pushButton_7_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqPushButtonAlert7"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqPushButtonAlert7"
                                                                data-parent="#accordionFaqPushButtonAlert"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_pushButton_7_text") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/86.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/86.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- Question 8 -->
                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqPushButtonAlert8">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqPushButtonAlert8"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqPushButtonAlert8"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_pushButton_8_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqPushButtonAlert8"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqPushButtonAlert8"
                                                                data-parent="#accordionFaqPushButtonAlert"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_pushButton_8_text") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/87.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/87.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- Question 9 -->
                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqPushButtonAlert9">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqPushButtonAlert9"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqPushButtonAlert9"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_pushButton_9_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqPushButtonAlert9"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqPushButtonAlert9"
                                                                data-parent="#accordionFaqPushButtonAlert"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_pushButton_9_text") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/88.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/88.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- Question 10 -->
                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqPushButtonAlert10">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqPushButtonAlert10"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqPushButtonAlert10"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_pushButton_10_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqPushButtonAlert10"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqPushButtonAlert10"
                                                                data-parent="#accordionFaqPushButtonAlert"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_pushButton_10_text") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/89.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/89.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- Question 11 -->
                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqPushButtonAlert11">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqPushButtonAlert11"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqPushButtonAlert11"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_pushButton_11_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqPushButtonAlert11"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqPushButtonAlert11"
                                                                data-parent="#accordionFaqPushButtonAlert"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_pushButton_11_text") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/90.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/90.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- Question 12 -->
                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqPushButtonAlert12">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqPushButtonAlert12"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqPushButtonAlert12"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_pushButton_12_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqPushButtonAlert12"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqPushButtonAlert12"
                                                                data-parent="#accordionFaqPushButtonAlert"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_pushButton_12_text") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/91.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/91.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- Question 13 -->
                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqPushButtonAlert13">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqPushButtonAlert13"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqPushButtonAlert13"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_pushButton_13_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqPushButtonAlert13"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqPushButtonAlert13"
                                                                data-parent="#accordionFaqPushButtonAlert"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_pushButton_13_text") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/92.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/92.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- Question 14 -->
                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqPushButtonAlert14">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqPushButtonAlert14"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqPushButtonAlert14"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_pushButton_14_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqPushButtonAlert14"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqPushButtonAlert14"
                                                                data-parent="#accordionFaqPushButtonAlert"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_pushButton_14_text") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/93.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/93.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- Question 15 -->
                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqPushButtonAlert15">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqPushButtonAlert15"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqPushButtonAlert15"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_pushButton_15_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqPushButtonAlert15"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqPushButtonAlert15"
                                                                data-parent="#accordionFaqPushButtonAlert"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_pushButton_15_text") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/94.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/94.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- Question 16 -->
                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqPushButtonAlert16">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqPushButtonAlert16"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqPushButtonAlert16"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_pushButton_16_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqPushButtonAlert16"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqPushButtonAlert16"
                                                                data-parent="#accordionFaqPushButtonAlert"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_pushButton_16_text") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/95.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/95.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- Question 17 -->
                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqPushButtonAlert17">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqPushButtonAlert17"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqPushButtonAlert17"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_pushButton_17_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqPushButtonAlert17"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqPushButtonAlert17"
                                                                data-parent="#accordionFaqPushButtonAlert"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    {{ $t("faq_pushButton_17_text") }}
                                                                    <div class="divImgFaq">
                                                                        <img
                                                                            v-if="this.$i18n.locale === 'fr'"
                                                                            class="imgFaq"
                                                                            src="/assets/faq/fr/96.png"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            class="imgFaq"
                                                                            src="/assets/faq/en/96.png"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- Question 18 -->
                                                        <div class="card">
                                                            <div class="card-header" id="headingFaqPushButtonAlert18">
                                                                <div
                                                                    class="card-title collapsed"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapseFaqPushButtonAlert18"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFaqPushButtonAlert18"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                        width="24px"
                                                                        height="24px"
                                                                        viewBox="0 0 24 24"
                                                                        version="1.1"
                                                                        class="kt-svg-icon"
                                                                    >
                                                                        <g
                                                                            stroke="none"
                                                                            stroke-width="1"
                                                                            fill="none"
                                                                            fill-rule="evenodd"
                                                                        >
                                                                            <polygon
                                                                                points="0 0 24 0 24 24 0 24"
                                                                            ></polygon>
                                                                            <path
                                                                                d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                            ></path>
                                                                            <path
                                                                                d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                                                                fill="#000000"
                                                                                fill-rule="nonzero"
                                                                                opacity="0.3"
                                                                                transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                    {{ $t("faq_pushButton_18_title") }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                id="collapseFaqPushButtonAlert18"
                                                                class="collapse"
                                                                aria-labelledby="headingFaqPushButtonAlert18"
                                                                data-parent="#accordionFaqPushButtonAlert"
                                                                style=""
                                                            >
                                                                <div class="card-body">
                                                                    <ol class="listFaq">
                                                                        <li>{{ $t("faq_pushButton_18_text1") }}</li>
                                                                        <li>{{ $t("faq_pushButton_18_text2") }}</li>
                                                                        <li>{{ $t("faq_pushButton_18_text3") }}</li>
                                                                        <li>{{ $t("faq_pushButton_18_text4") }}</li>
                                                                    </ol>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- end:: Content -->
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import TroubleshootingFaqVue from "./faqsections/troubleshootingfaq.vue";

export default {
    data() {
        return {
            releasedFor: process.env.VUE_APP_RELEASED_FOR,
            fileLink: null,
            helpVideoLink: "https://www.omniaccess-stellar-asset-tracking.com/help/videos",
        };
    },
    created: function() {
        console.log("Component(faq)::created() - called");
        switch (this.$i18n.locale) {
            case "fr":
                this.fileLink = "/assets/faq/pdf/faq-fr.pdf";
                break;
            case "en":
                this.fileLink = "/assets/faq/pdf/faq-en.pdf";
                break;
            default:
                this.fileLink = "/assets/faq/pdf/faq-en.pdf";
                break;
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["user"])
    },

    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        user(user) {
            console.log("Component(faq)::watch(used): ", user);
            if (user) {
                switch (user.preferredLanguage) {
                    case "fr":
                        this.fileLink = "/assets/faq/pdf/faq-fr.pdf";
                        break;s
                    case "en":
                        this.fileLink = "/assets/faq/pdf/faq-en.pdf";
                        break;
                    default:
                        this.fileLink = "/assets/faq/pdf/faq-en.pdf";
                        break;
                }
            }
        }
    },

    methods: {
    },

    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-troubleshootingfaq": TroubleshootingFaqVue,
    }
}
</script>

<style>
.baseTemplate {
  display: contents;
}
.imgFaq {
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  border: 1px solid #dfdee4;
}
.divImgFaq {
  text-align: center;
  margin: 11px 0px;
}
.listFaq {
  list-style: circle;
  margin-top: 10px;
}

.imgScaling100 {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}

.btnTextFaq {
    margin-left: 0.1rem;
    margin-right: 0.1rem;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
}
.btnFontFaq{
    font-size: 0.8rem !important;
}
</style>
